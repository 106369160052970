import { PAYMENTS_TYPES } from "../config/payment-types.config";
import * as i0 from "@angular/core";
var PosSettingsService = /** @class */ (function () {
    function PosSettingsService() {
        this.PAYMENTS_TYPES = PAYMENTS_TYPES;
        this.storage = sessionStorage;
        this.isPaymentsAvailableKey = 'isPaymentsAvailable';
        this.redeemsVisibilityKey = 'isRedeemsVisible';
        this.supportVisibilityKey = 'isSupportVisible';
        this.storeVisibilityKey = 'isStoreVisible';
    }
    PosSettingsService.prototype.setRedeemsVisibility = function (redeems) {
        var state;
        if (!redeems) {
            state = 1;
        }
        if (redeems) {
            this.redeems = redeems;
            if (redeems.find(function (item) { return +item.status === 1; })) {
                state = 1;
            }
            else {
                state = 0;
            }
        }
        this.storage.setItem(this.redeemsVisibilityKey, state);
    };
    PosSettingsService.prototype.getRedeemsVisibility = function () {
        return !!+this.storage.getItem(this.redeemsVisibilityKey);
    };
    PosSettingsService.prototype.removeRedeemsVisibility = function () {
        this.storage.removeItem(this.redeemsVisibilityKey);
    };
    // setCheckOutPageVisibility
    PosSettingsService.prototype.setIfPaymentsAvailable = function (payments) {
        this.payments = payments;
        var state;
        if (!payments) {
            state = 1;
        }
        if (payments) {
            if (payments.find(function (item) { return +item.status === 1; })) {
                state = 1;
            }
            else {
                state = 0;
            }
        }
        // state = 0;
        this.storage.setItem(this.isPaymentsAvailableKey, state);
    };
    // getCheckOutPageVisibility()
    PosSettingsService.prototype.getIfPaymentsAvailable = function () {
        return !!+this.storage.getItem(this.isPaymentsAvailableKey);
    };
    PosSettingsService.prototype.removePaymentsAvailable = function () {
        this.storage.removeItem(this.isPaymentsAvailableKey);
    };
    PosSettingsService.prototype.setStorePageVisibility = function (val) {
        var isStorePageVisible = val && val !== 'false' ? '1' : '0';
        this.storage.setItem(this.storeVisibilityKey, isStorePageVisible);
    };
    // getCheckOutPageVisibility()
    PosSettingsService.prototype.getStorePageVisibility = function () {
        return !!+this.storage.getItem(this.storeVisibilityKey);
    };
    PosSettingsService.prototype.isStorePageVisibilityExist = function () {
        var storePageVisibility = this.storage.getItem(this.storeVisibilityKey);
        return (storePageVisibility === '1') || (storePageVisibility === '0');
    };
    PosSettingsService.prototype.isPaymentsVisibilityExist = function () {
        var storePageVisibility = this.storage.getItem(this.isPaymentsAvailableKey);
        return (storePageVisibility === '1') || (storePageVisibility === '0');
    };
    PosSettingsService.prototype.removeStorePageVisibility = function () {
        this.storage.removeItem(this.storeVisibilityKey);
    };
    PosSettingsService.prototype.isCardsVisible = function () {
        var _this = this;
        if (this.payments) {
            if (this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.card; })) {
                return !!+this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.card; }).status;
            }
            return false;
        }
        return true;
    };
    PosSettingsService.prototype.isCashAppVisible = function () {
        var _this = this;
        if (this.payments) {
            if (this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.cashApp; })) {
                return !!+this.payments.find(function (el) { return el.id === _this.PAYMENTS_TYPES.cashApp; }).status;
            }
            return false;
        }
        return true;
    };
    PosSettingsService.prototype.setSupportVisible = function (val) {
        if ((+val === 1) || (+val === 0)) {
            this.storage.setItem(this.supportVisibilityKey, val);
        }
        else {
            this.storage.setItem(this.supportVisibilityKey, '0');
        }
    };
    PosSettingsService.prototype.getSupportVisibility = function () {
        return !!+this.storage.getItem(this.supportVisibilityKey);
    };
    PosSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PosSettingsService_Factory() { return new PosSettingsService(); }, token: PosSettingsService, providedIn: "root" });
    return PosSettingsService;
}());
export { PosSettingsService };
