import * as tslib_1 from "tslib";
import { OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import { TRANSACTIONS_LIST_TYPE } from "../../shared/senetpro-transactions/senetpro-transactions.model";
import { SHOP_POPUP_TYPE } from "../shop/shop.config";
import { CHANGE_USER_INFO_TYPE } from "../../shared/popup-change-user-info/popup-change-user-info.config";
import { POPUP_MORE_IMAGES_TYPE } from "../../shared/popup-more-images/popup-more-images.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../service/account.service";
import * as i3 from "../../service/loader.service";
import * as i4 from "../../service/shop.service";
import * as i5 from "../../service/wallet.service";
import * as i6 from "../../service/phoneMask.service";
import * as i7 from "../../modules/language/language.service";
import * as i8 from "../../service/content-displaying.service";
import * as i9 from "../../service/pos-settings.service";
import * as i10 from "../../shared/popup/popup.component";
var AccountComponent = /** @class */ (function () {
    function AccountComponent(formBuilder, accountService, loaderService, shopService, walletService, phoneMaskService, languageService, contentDisplayingService, posSettingsService, popupComponent) {
        this.formBuilder = formBuilder;
        this.accountService = accountService;
        this.loaderService = loaderService;
        this.shopService = shopService;
        this.walletService = walletService;
        this.phoneMaskService = phoneMaskService;
        this.languageService = languageService;
        this.contentDisplayingService = contentDisplayingService;
        this.posSettingsService = posSettingsService;
        this.popupComponent = popupComponent;
        this.TRANSACTIONS_LIST_TYPE = TRANSACTIONS_LIST_TYPE;
        this.CHANGE_USER_INFO_TYPE = CHANGE_USER_INFO_TYPE;
        this.entriesVal = 0;
        this.redeemedVal = 0;
        this.bitcoinAmount = 0;
        this.availableBalance = 0;
        this.providers = [];
        this.RouterPaths = RouterPaths;
        this.moreThenAvailablePayout = false;
        this.showMoreTransactionsBtn = false;
        this.showMoreLibraryImages = false;
        this.showMoreLibraryImagesBtn = false;
        this.showMoreLibraryNFT = false;
        this.showMoreLibraryNFTBtn = false;
        this.transactionsList = [];
        this.imagesList = [];
        this.popupChannels = { show: false };
        this.popupTransactions = { show: false };
        this.popupConfirmInfo = { show: false, totalDebit: 0 };
        this.popupShop = { show: false };
        this.popupChangeUserInfo = { show: false };
        this.popupImages = {
            show: false,
            type: null
        };
        this.showLibraryBigImagePopup = false;
        this.privateKeyJson = null;
        this.nftData = {
            library: [],
            qrCode: '',
            wallet: '',
            nftDescription: ''
        };
        this.notifyEmail = false;
        this.notifyText = false;
        this.isPhoneVerified = false;
        this.isEmailVerified = false;
        this.isCheckOutVisible = true;
    }
    AccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(function (val) {
            if (val) {
                setTimeout(function () {
                    _this.getPageInfo();
                });
            }
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
        this.pageForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
        });
        this.contactsForm = this.formBuilder.group({
            firstName: [null],
            lastName: [null],
            address: [null],
            email: [null],
            phone: [null],
        });
        this.getPageInfo();
    };
    AccountComponent.prototype.ngOnDestroy = function () {
        this.languageChangeSubscription.unsubscribe();
    };
    AccountComponent.prototype.getPageInfo = function (page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage, only_balance) {
        var _this = this;
        if (page === void 0) { page = 1; }
        if (pageAi === void 0) { pageAi = 1; }
        if (pageNft === void 0) { pageNft = 1; }
        if (singleHistoryPage === void 0) { singleHistoryPage = false; }
        if (singleAiHistoryPage === void 0) { singleAiHistoryPage = false; }
        if (singleNftHistoryPage === void 0) { singleNftHistoryPage = false; }
        if (only_balance === void 0) { only_balance = false; }
        this.walletService.repeatFunction = this.getPageInfo.bind(this);
        this.loaderService.show();
        this.accountService.getPageInfo(page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage).subscribe(function (data) {
            _this.setPageInfo(data, only_balance);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    AccountComponent.prototype.setPageInfo = function (data, only_balance) {
        try {
            this.walletService.resetRepeatFunction();
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);
            this.bitcoinAmount = data.btcAmount;
            if (only_balance) {
                return;
            }
            else {
                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.isCheckOutVisible = this.posSettingsService.getIfPaymentsAvailable();
                this.bitcoinDescription = data.btcDescription;
                this.btcTransactionsLink = data.btcTransactionsLink;
                this.nftDescription = data.nftDescription;
                this.nftData.qrCode = data.nftAddressQrCode;
                this.nftData.wallet = data.nftAddress;
                this.nftData.nftDescription = data.nftSecondDescription;
                this.buyDescription = data.buyDescription;
                this.sellDescription = data.sellDescription;
                this.sendDescription = data.sendDescription;
                this.receiveDescription = data.receiveDescription;
                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                }
                this.setTransactionsList(data.products, data.productsLimit, data.productsTotal);
                this.setImagesLibraryList(data.aiImages, data.aiLimit, data.aiTotal);
                this.setNftLibraryList(data.nftImages, data.nftLimit, data.nftTotal);
                this.setContacts(data);
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    AccountComponent.prototype.setContacts = function (data) {
        if (data && data.settings) {
            var info = data.settings;
            var firstName = info.firstName ? info.firstName : null;
            var lastName = info.lastName ? info.lastName : null;
            var address = info.address ? info.address : null;
            var email = info.email ? info.email : null;
            var phone = info.phone ? info.phone : null;
            this.contactsForm.controls.firstName.setValue(firstName);
            this.contactsForm.controls.lastName.setValue(lastName);
            this.contactsForm.controls.address.setValue(address);
            this.contactsForm.controls.email.setValue(email);
            this.contactsForm.controls.firstName.setValue(firstName);
            this.contactsForm.controls.phone.setValue(phone);
            this.isPhoneVerified = !!info.phoneVerify;
            this.isEmailVerified = !!info.emailVerify;
            this.notifyText = info.notifyText ? info.notifyText : false;
            this.notifyEmail = info.notifyEmail ? info.notifyEmail : false;
        }
    };
    AccountComponent.prototype.setGamesProviders = function (providers) {
        this.providers = providers;
        this.selectedProvider = this.providers.find(function (provider) { return provider.default; });
        this.pageForm.controls.method.setValue(this.selectedProvider);
    };
    AccountComponent.prototype.onInputAmount = function () {
        var avaliable = typeof this.pageForm.controls.method.value.balance === 'number' ? this.pageForm.controls.method.value.balance : Number.parseFloat(this.pageForm.controls.method.value.balance);
        var entered = Number.parseFloat(this.pageForm.controls.amount.value);
        if (avaliable < entered) {
            this.pageForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        }
        else {
            this.moreThenAvailablePayout = false;
        }
    };
    AccountComponent.prototype.setTransactionsList = function (history, limit, total) {
        this.showMoreTransactionsBtn = total > limit;
        this.transactionsList = history;
    };
    AccountComponent.prototype.setImagesLibraryList = function (history, limit, total) {
        this.showMoreLibraryImagesBtn = total > limit;
        var list = tslib_1.__spread(history);
        if (list.length > 6) {
            list.length = 6;
        }
        this.imagesList = list;
    };
    AccountComponent.prototype.setNftLibraryList = function (history, limit, total) {
        this.showMoreLibraryNFTBtn = total > limit;
        var list = tslib_1.__spread(history);
        if (list.length > 6) {
            list.length = 6;
        }
        this.nftData.library = list;
    };
    AccountComponent.prototype.showTransactionsPopup = function () {
        document.body.classList.add('hide-menu');
        this.popupTransactions = {
            show: true
        };
    };
    AccountComponent.prototype.closeTransactionsPopup = function () {
        document.body.classList.remove('hide-menu');
        this.popupTransactions = {
            show: false
        };
    };
    AccountComponent.prototype.showImagesLibraryPopup = function () {
        document.body.classList.add('hide-menu');
        this.popupImages = {
            show: true,
            type: POPUP_MORE_IMAGES_TYPE.image
        };
    };
    AccountComponent.prototype.showNFTLibraryPopup = function () {
        document.body.classList.add('hide-menu');
        this.popupImages = {
            show: true,
            type: POPUP_MORE_IMAGES_TYPE.nft
        };
    };
    AccountComponent.prototype.closeImagesPopup = function () {
        document.body.classList.remove('hide-menu');
        this.popupImages = {
            show: false,
            type: null
        };
    };
    AccountComponent.prototype.onSellCrypto = function () {
        var _this = this;
        this.walletService.repeatFunction = this.onSellCrypto.bind(this);
        this.shopService.showSellCryptoPopup().subscribe(function (data) {
            _this.sellCrypto(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    AccountComponent.prototype.sellCrypto = function (data) {
        try {
            this.walletService.resetRepeatFunction();
            if (data.status) {
                var rate = data.price;
                var crypto_1 = data.bitcoinAddress;
                var qrCode = data.bitcoinQrcodeLink;
                var btc = data.bitcoinValue;
                this.onPopupShopOpen(data.description, SHOP_POPUP_TYPE.sellCrypto, null, rate, crypto_1, btc, qrCode);
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    AccountComponent.prototype.onPopupShopOpen = function (title, type, id, rate, crypto, btc, qrCode, address, privateKey, publicKey, wif) {
        if (id === void 0) { id = null; }
        if (rate === void 0) { rate = null; }
        if (crypto === void 0) { crypto = null; }
        if (btc === void 0) { btc = null; }
        if (qrCode === void 0) { qrCode = null; }
        if (address === void 0) { address = null; }
        if (privateKey === void 0) { privateKey = null; }
        if (publicKey === void 0) { publicKey = null; }
        if (wif === void 0) { wif = null; }
        document.body.classList.add('hide-menu');
        var availableBalance = rate && btc ? rate * btc : type === SHOP_POPUP_TYPE.sendCrypto ? btc : this.entriesVal;
        this.popupShop = {
            show: true,
            title: title,
            type: type,
            id: id,
            rate: rate,
            crypto: crypto,
            btc: btc,
            qrCode: qrCode,
            address: address,
            privateKey: privateKey,
            publicKey: publicKey,
            wif: wif,
            availableBalance: availableBalance
        };
    };
    AccountComponent.prototype.onPopupShopClose = function () {
        document.body.classList.remove('hide-menu');
        this.popupShop = {
            show: false,
            title: '',
            type: null
        };
    };
    AccountComponent.prototype.onSendPopupForm = function ($event) {
        var _this = this;
        if (this.popupShop.type === SHOP_POPUP_TYPE.revealKey) {
        }
        else if (this.popupShop.type === SHOP_POPUP_TYPE.revealKeyPassword) {
            var password = $event.password;
            this.accountService.onRevealPrivateKey(password).subscribe(function (data) {
                _this.setRevealKeyPopup(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else if (this.popupShop.type === SHOP_POPUP_TYPE.sendCrypto) {
            var amount = $event.amount;
            var address = $event.address;
            var password = $event.password;
            this.accountService.onSendCrypto(amount, address, password).subscribe(function (data) {
                _this.setActivateBuyRequestResult(data, true);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            var amount = $event.amount;
            var password = $event.password;
            this.shopService.onSellCryptoRequest(amount, password).subscribe(function (data) {
                _this.setActivateBuyRequestResult(data, true);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    AccountComponent.prototype.setActivateBuyRequestResult = function (data, updateBalance) {
        if (updateBalance === void 0) { updateBalance = false; }
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                if (updateBalance) {
                    this.getPageInfo();
                }
                this.onPopupShopClose();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    AccountComponent.prototype.showRevealKeyPopup = function () {
        var message = this.languageService.getString('messagesData', 'askToEnterPassword');
        this.onPopupShopOpen(message, SHOP_POPUP_TYPE.revealKeyPassword);
    };
    AccountComponent.prototype.setRevealKeyPopup = function (data) {
        var _this = this;
        this.initShowShopPopup(data, function () {
            var address = data.address;
            var privateKey = data.privateKey;
            var publicKey = data.publicKey;
            var wif = data.wif;
            _this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.revealKey, null, null, null, null, null, address, privateKey, publicKey, wif);
        });
    };
    AccountComponent.prototype.onReceiveCrypto = function () {
        var _this = this;
        this.accountService.onReceiveCrypto().subscribe(function (data) {
            _this.showReceivePopup(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    AccountComponent.prototype.showReceivePopup = function (data) {
        var _this = this;
        this.initShowShopPopup(data, function () {
            var address = data.address;
            _this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.receiveCrypto, null, null, null, null, null, address);
        });
    };
    AccountComponent.prototype.onSendCrypto = function () {
        var btc = this.bitcoinAmount;
        var title = this.languageService.getString('sendCrypto');
        this.onPopupShopOpen(title, SHOP_POPUP_TYPE.sendCrypto, null, null, null, btc);
    };
    AccountComponent.prototype.initShowShopPopup = function (data, successFunction) {
        try {
            if (data.status) {
                successFunction();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    AccountComponent.prototype.onCheckboxChange = function (event, input) {
        if (input === 'text') {
            this.notifyText = event.target.checked;
        }
        else {
            this.notifyEmail = event.target.checked;
        }
    };
    AccountComponent.prototype.onSaveContcts = function () {
        var _this = this;
        var firstName = this.contactsForm.controls.firstName.value;
        var lastName = this.contactsForm.controls.lastName.value;
        var address = this.contactsForm.controls.address.value;
        this.accountService.onSaveContacts(firstName, lastName, address, this.notifyText, this.notifyEmail).subscribe(function (data) {
            _this.showSaveContactsResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    AccountComponent.prototype.showSaveContactsResult = function (data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    AccountComponent.prototype.changeUserInfo = function (type) {
        var _this = this;
        if ((type === CHANGE_USER_INFO_TYPE.emailVerify) || (type === CHANGE_USER_INFO_TYPE.phoneVerify)) {
            if (type === CHANGE_USER_INFO_TYPE.emailVerify) {
                this.accountService.onRequestVerifyEmail().subscribe(function (data) {
                    try {
                        if (data.status) {
                            _this.popupComponent.showPopupSuccess({ text: data.message });
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    catch (e) {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
            else if (type === CHANGE_USER_INFO_TYPE.phoneVerify) {
                this.accountService.onRequestVerifyPhone().subscribe(function (data) {
                    try {
                        if (data.status) {
                            _this.showPopupChangeInfo(type);
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    catch (e) {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        }
        else {
            this.showPopupChangeInfo(type);
        }
    };
    AccountComponent.prototype.showPopupChangeInfo = function (type) {
        document.body.classList.add('hide-menu');
        this.popupChangeUserInfo = {
            show: true,
            type: type,
            email: (this.contactsForm.controls.email.value ? this.contactsForm.controls.email.value : null),
            phone: (this.contactsForm.controls.phone.value ? this.contactsForm.controls.phone.value : null),
            phoneLastFour: (this.contactsForm.controls.phone.value ? this.contactsForm.controls.phone.value.substr(-4) : null),
        };
    };
    AccountComponent.prototype.closeChangeUserInfoPopup = function () {
        document.body.classList.remove('hide-menu');
        this.popupChangeUserInfo = {
            show: false,
            type: null
        };
    };
    AccountComponent.prototype.onVerified = function (type) {
        if (type === 'phone') {
            this.isPhoneVerified = true;
        }
        else if (type === 'email') {
            this.isEmailVerified = true;
        }
    };
    AccountComponent.prototype.setChangedUserInfo = function ($event) {
        var type = $event.type;
        var value = $event.value;
        if (type === CHANGE_USER_INFO_TYPE.email) {
            this.contactsForm.controls.email.setValue(value);
        }
        else if (type === CHANGE_USER_INFO_TYPE.phone) {
            this.contactsForm.controls.phone.setValue(value);
        }
    };
    AccountComponent.prototype.showAdditionalContent = function () {
        return this.contentDisplayingService.showAdditionContent();
    };
    AccountComponent.prototype.onShowLibraryBigImagePopup = function (slide) {
        // document.body.classList.add('hide-menu');
        // document.querySelector('.login__forms').classList.add('blur');
        this.showLibraryBigImagePopup = true;
        this.libraryBigImagePopupData = {
            data: slide
        };
    };
    AccountComponent.prototype.onPopupLibraryBigImageClose = function () {
        this.showLibraryBigImagePopup = false;
        // document.querySelector('.login__forms').classList.remove('blur');
    };
    AccountComponent.prototype.onLibraryImageCheckboxChange = function (e, item) {
        var _this = this;
        var oldStatus = e.target.checked;
        var status = oldStatus ? 0 : 1;
        var uuid = item.uuid;
        this.accountService.onLibraryImageStatusChange(uuid, status).subscribe(function (data) {
            try {
                if (data.status) {
                    var changedImage = _this.imagesList.find(function (el) { return el.uuid === uuid; });
                    if (changedImage) {
                        changedImage.privacy_status = status;
                    }
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                    e.target.checked = !oldStatus;
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
                e.target.checked = !oldStatus;
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
            e.target.checked = !oldStatus;
        }, function () {
            _this.loaderService.hide();
        });
    };
    AccountComponent.prototype.onLibraryImageCheckboxPopupChange = function (e) {
        this.onLibraryImageCheckboxChange(e.event, e.item);
    };
    AccountComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountComponent_Factory() { return new AccountComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AccountService), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.ShopService), i0.ɵɵinject(i5.WalletService), i0.ɵɵinject(i6.PhoneMaskService), i0.ɵɵinject(i7.LanguageService), i0.ɵɵinject(i8.ContentDisplayingService), i0.ɵɵinject(i9.PosSettingsService), i0.ɵɵinject(i10.PopupComponent)); }, token: AccountComponent, providedIn: "root" });
    return AccountComponent;
}());
export { AccountComponent };
