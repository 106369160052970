import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {ShopComponent} from "./shop/shop.component";
import {ContactsFormComponent} from "./contacts-form/contacts-form.component";
import {SupportComponent} from "./support/support.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {PerksComponent} from "./perks/perks.component";
import {AccountComponent} from "./account/account.component";
import {CommissionComponent} from "./commission/commission.component";
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {SenetproComponent} from "./promo/senetpro/senetpro.component";
import {PartnerComponent} from "./partner/partner.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";

const routes: Routes = [
    {
        path: '', component: PartnerComponent, children: [
            {path: '', component: LoginComponent},
            // {path: 'test-cash-app', component: TestCashAppComponent},
            {path: 'senetpro', component: SenetproComponent},
            {path: 'password/restore', component: ResetPasswordComponent},
            {path: 'email/verify', component: VerifyEmailComponent},
            {path: 'home', component: HomeComponent, children: [
            // {path: 'home', component: HomeComponent, children: [
            //         {path: 'test-cash-app-checkout', component: CheckoutCashAppComponent},
                    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    {path: 'contacts', component: ContactsFormComponent},
                    {path: 'shop', component: ShopComponent, canActivate: [AuthGuard]},
                    {path: 'checkout', component: CheckoutComponent, canActivate: [AuthGuard]},
                    {path: 'perks', component: PerksComponent, canActivate: [AuthGuard]},
                    {path: 'support', component: SupportComponent, canActivate: [AuthGuard]},
                    {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
                    {path: 'commission', component: CommissionComponent, canActivate: [AuthGuard]},
                    {path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule)},
              ]},
            {path: '**', redirectTo: '', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
