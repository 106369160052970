import {
    Component,
    OnInit, Injectable
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {RouterPaths} from "../../config/router-paths.model";
import {LoaderService} from "../../service/loader.service";
import {CheckoutService} from "../../service/checkout.service";
import {ScrollToService} from "../../service/scrollTo.service";
import {CardsService} from "../../service/cards.service";
import {PROJECT_MESSAGES} from "../../config/project";
import {StatesModel} from "../../models/states/states.model";
import {POPUP_DEBIT_CARD_TYPE} from "../../shared/popup-debit-card/popup-debit-card.config";
import {WalletService} from "../../service/wallet.service";
import {PosSettingsService} from "../../service/pos-settings.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {CHECKOUT_CHARGE_PAYMENT_TYPE} from "../../shared/checkout-charge-popup/checkout-charge-popup.config";
import {ContentDisplayingService} from "../../service/content-displaying.service";

const pageSteps = {
    initial: 1,
    typeSelection: 2,
    settings: 3,
    terms: 4,
    generation: 5,
    spinner: 6
}

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
@Injectable({providedIn: 'root'})
export class CheckoutComponent implements OnInit {
    checkOutForm: FormGroup;

    entriesVal = 0;
    redeemedVal = 0;

    pageSteps = pageSteps;
    step = 1;

    unitsCost = 0;
    aiUnits = 0;
    aiUnitCost = 0;

    isUnfinished = false;
    showMint = false;
    mintAmount = 0;

    defaultKeyWords;

    brushes = [];
    genBrushes = [];

    mintBrushesPage = 1;
    mintBrushes = [];
    mintBrushesTotal;
    mintBrushesSelected = [];
    selectedBrush;

    generateDefaultImage = "";
    generatedImagesAmount;
    generateAllImagesAmount;

    spinnerData;

    terms: '';
    isTermsExpandedShown = false;

    RouterPaths = RouterPaths;
    showCashApp = false;
    cards = [];
    activeMethod;
    activeCard;
    games = [];

    popupDebitCardInfo: any = {show: false};

    showNFTPopup = false;
    NFTPopupData;

    moreThenAvailablePayout = false;

    iframePopup = {
        show: false,
        url: null
    }

    verifyPopup = {
        show: false,
        id: null
    }

    states: Array<StatesModel> = [];
    cashappInfo;

    viewIsCashAppHidden = false;
    viewIsCardsHidden = false;
    languageChangeSubscription;

    showLibraryBigImagePopup = false;
    libraryBigImagePopupData;
    generationIsFinished = false;
    constructor(
        private currencyService: CurrencyService,
        private formBuilder: FormBuilder,
        private checkoutService: CheckoutService,
        private loaderService: LoaderService,
        private scrollToService: ScrollToService,
        private activatedRoute: ActivatedRoute,
        private posSettingsService: PosSettingsService,
        private cardsService: CardsService,
        private walletService: WalletService,
        private languageService: LanguageService,
        private contentDisplayingService: ContentDisplayingService,
        private router: Router,
        private popupComponent: PopupComponent) {

    }
    ngOnInit() {
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(val => {
            if (val) {
                setTimeout(() => {
                    this.getPageInfo(false, false, true);
                });
            }
        });
        document.body.classList.remove('hide-menu');
        this.checkOutForm = this.formBuilder.group({
            name: [null, Validators.required],
            generation: ['instant', Validators.required],
            terms: [false, Validators.required],
        });

        this.getPageInfo();
    }

    ngOnDestroy() {
        this.languageChangeSubscription.unsubscribe();
    }

    getPageInfo(onlyBalance = false, onlyCards = false, language = false) {
        this.checkoutService.getPageInfo(onlyBalance).subscribe(data => {
            this.setPageInfo(data, onlyBalance, onlyCards, language);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setPageInfo(data, only_balance, onlyCards, language) {
        try {
            if (onlyCards) {
                this.cards = [];
                if (data.cards && data.cards.length) {
                    this.cards = data.cards;
                    this.activeCard = this.cards[this.cards.length-1];
                    this.activeMethod = this.cards[this.cards.length-1];
                    this.scrollToLastMethod();
                }
                return;
            }
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);
            if (only_balance) {
                return;
            } else {
                if (!language) {
                  this.generationIsFinished = false;
                }
                this.games = data.games;
                this.defaultKeyWords = data.aiDefaultText;

                this.terms = data.terms;

                this.brushes = [];
                if (data.aiStyles) {
                    this.brushes = data.aiStyles;
                    if (!language) {
                        this.selectedBrush = data.aiStyles[0].id;
                    }
                }

                if (language) {
                    return;
                }

                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.viewIsCashAppHidden = !this.posSettingsService.isCashAppVisible();
                this.viewIsCardsHidden = !this.posSettingsService.isCardsVisible();

                this.cards = [];
                if (data.cards && data.cards.length) {
                    this.cards = data.cards;
                    this.activeCard = this.cards[this.cards.length-1];
                    this.activeMethod = this.cards[this.cards.length-1];
                    this.scrollToLastMethod();
                }

                document.getElementById('keyWords').innerText = '';
                (document.getElementById('keyWords') as HTMLTextAreaElement).value = '';

                this.checkOutForm.controls.terms.setValue(false);
                this.checkOutForm.controls.generation.setValue('instant');

                this.cashappInfo = data.cashappInfo;

                if(this.posSettingsService.isCashAppVisible() && !this.posSettingsService.isCardsVisible()){
                    this.showCashApp = true;
                }

                if (!this.posSettingsService.getIfPaymentsAvailable()) {
                    this.router.navigate([RouterPaths.shop]);
                    return;
                }

                this.unitsCost = this.isUnfinished ? data.aiAmount : 0;
                this.aiUnits = this.isUnfinished ? data.aiUnits : 0;
                this.aiUnitCost = data.aiPrice;

                this.isUnfinished = data.aiImagesStatus;
                this.showMint = data.nftImagesStatus;
                if (this.showMint) {
                    this.mintBrushesPage = 1;
                    this.getMintInfo(this.mintBrushesPage);
                }

                this.states = data.states;
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onShowCashApp(val) {
        this.showCashApp = val;
    }

    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

    closeDebitCardPopup(needUpdate = false) {
        document.body.classList.remove('hide-menu');
        this.popupDebitCardInfo = {
            show: false,
        };
        if (needUpdate) {
            this.getPageInfo(false, true);
        }
    }

    showDebitCardPopup() {
        document.body.classList.add('hide-menu');
        this.popupDebitCardInfo = {
            show: true,
            type: POPUP_DEBIT_CARD_TYPE.simpleCard
        }
    }

    onSubmit() {
        if (!this.showCashApp) {
            if (!this.cards.length) {
                this.popupComponent.showPopupError({text: this.languageService.getString('messagesData', 'askToEnterPaymentMethod')});
            } else {
                if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
                    this.showNoVerifyErrorMessage();
                } else {
                    this.showConfirmMessage();
                }
            }
        } else {
            this.showConfirmMessage();
        }
    }

    showNoVerifyErrorMessage() {
        if (this.activeMethod && this.activeMethod.last_four) {
            const message = this.activeMethod.description ?
              this.languageService.getStringWithVariables(
                [{description: this.activeMethod.description}, {lastFour: this.activeMethod.last_four}],
                'messagesData',
                'askVerifyCardEndingIn') + ` ${this.activeMethod.last_four}` :
              `${this.languageService.getString('messagesData', 'askVerifyCardEndingIn')} ${this.activeMethod.last_four}.`;

              // `Please verify ${this.activeMethod.description} - ${this.activeMethod.last_four} first.` :
              // `Please verify card ending in ${this.activeMethod.last_four}.`;

            this.popupComponent.showPopupError({text: message});
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
        return;
    }

    showConfirmMessage() {
        this.walletService.repeatFunction = this.showConfirmMessage.bind(this);

        let cardId;
        if (!this.showCashApp) {
            cardId = this.activeMethod.id;
        }
        const amount = this.unitsCost;
        const generation = this.checkOutForm.controls.generation.value;
        const text = (document.getElementById('keyWords') as HTMLTextAreaElement).value;
        const selectedImage = this.selectedBrush;

        if (amount && generation && text && selectedImage && ((cardId && !this.showCashApp) || (!cardId && this.showCashApp))) {
            this.onShowNFTPopup();
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
    }

    onShowNFTPopup() {
        const inputAmount = this.unitsCost;

        // if (!this.showCashApp) {
        //     const description = this.activeMethod.description;
        //     const number = this.activeMethod.last_four;
        //     data.cardInfo = {
        //         description,
        //         number
        //     }
        // }

        this.showNFTPopup = true;
        this.NFTPopupData = {
            data: {
                payment: this.showCashApp ? CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp : CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard,
                inputAmount: inputAmount
            }
        };

        if (this.showCashApp) {
            this.NFTPopupData.data.cashappInfo = this.cashappInfo;
        }

        this.openNFTPopup({showNFTPopup: true, data: this.NFTPopupData});
    }

    openNFTPopup($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
    }

    onPopupNFTClose() {
        document.body.classList.remove('hide-menu');
        this.showNFTPopup = false;
    }

    showIframePopup(url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        }
    }

    hideIframePopup(result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        };
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            } else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({text: result.message});
                    const generation = this.checkOutForm.controls.generation.value;
                    if (generation !== 'instant') {
                        this.showGeneration();
                    } else {
                        this.goToStep(this.pageSteps.initial);
                        this.getPageInfo();
                    }
                } else {
                    this.popupComponent.showPopupError({text: result.message, confirmButtonText: this.languageService.getString('ok')})
                }
            }
        }
    }

    onDepositConfirm($event) {
        const amount = this.unitsCost;
        const generation = this.checkOutForm.controls.generation.value;
        const text = (document.getElementById('keyWords') as HTMLTextAreaElement).value;
        const selectedImage = this.selectedBrush;

        if (this.showCashApp) {
            const token = $event.token;
            this.checkoutService.buyWithCashApp(selectedImage, amount, generation, text, token).subscribe(data => {
                this.setDepositConfirmResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            const cardId = this.activeMethod.id;
            this.checkoutService.buyWithCard(selectedImage, amount, generation, text, cardId).subscribe(data => {
                this.setDepositConfirmResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    setDepositConfirmResult(data) {
        try {
            if(data.status === true){
                this.onPopupNFTClose();
                if (data.threeDSecure && !this.showCashApp) {
                    if (data.threeDSecureUrl) {
                        this.showIframePopup(data.threeDSecureUrl);
                    } else {
                        this.loaderService.hideAndShowTryAgain();
                    }
                } else {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    const generation = this.checkOutForm.controls.generation.value;
                    if (generation !== 'instant') {
                        this.showGeneration();
                    } else {
                        this.goToStep(this.pageSteps.initial);
                        this.getPageInfo();
                    }
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onCloseVerifyPopup(e) {
        document.body.classList.remove('hide-menu');
        if (e) {
            this.cards.forEach(card => {
                if (card.id === this.verifyPopup.id) {
                    card.verify = 1;
                }
            });
        }
        this.verifyPopup.show = false;
        this.verifyPopup.id = null;

    }



    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount) ? this.currencyService.formatCurrency(amount) : this.currencyService.formatCurrency(0);
    }

    onUpdateUnits(val?) {
        if (val) {
            this.unitsCost = +this.unitsCost + val;
        } else {
            this.unitsCost = 0;
        }
        this.aiUnits = +this.unitsCost / +this.aiUnitCost;
    }

    onChangeSelectedBrush(id) {
        this.selectedBrush = id;
    }

    showCheckoutTerms() {
        document.body.classList.add('hide-menu');
        this.isTermsExpandedShown = true;
    }

    closeTermsExpanded($event) {
        document.body.classList.remove('hide-menu');
        this.isTermsExpandedShown = false;
    }

    goBack() {
        this.goToStep(this.step - 1);
    }

    goNext() {
        if (this.step === this.pageSteps.initial) {
            if (!+this.unitsCost) {
                this.popupComponent.showPopupError({text: this.languageService.getString('pleaseSelectAmountOfDeposit')});
                return;
            }
        }
        if (this.step === this.pageSteps.settings) {
            if (!(document.getElementById('keyWords') as HTMLTextAreaElement).value) {
                this.popupComponent.showPopupError({text: this.languageService.getString('pleasEnterKeywordsOrClickButton')});
                return;
            }
            if (!this.viewIsCashAppHidden && this.cashappInfo) {
                this.NFTPopupData = {
                    data: {
                        payment: CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp,
                        inputAmount: this.unitsCost,
                        cashappInfo: this.cashappInfo
                    }
                };
            }
        }
        this.goToStep(this.step + 1);
    }

    showMore() {
        const page = this.mintBrushesPage + 1;
        this.getMintInfo(page);
    }

    getMintInfo(page) {
        this.checkoutService.getMintInfo(page).subscribe(data => {
            try {
                if (data.status) {
                    this.mintAmount = +data.nfts;
                    this.mintBrushes = this.mintBrushes.concat(...data.images);
                    this.mintBrushesTotal = data.imagesTotal;
                    this.mintBrushesPage = page;
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onMint() {
        const images = Object.keys(this.mintBrushesSelected).map((key) => this.mintBrushesSelected[key].uuid);
        this.checkoutService.onMintImages(images).subscribe(data => {
            try {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.spinnerData = data.wheel;
                    if (data.bonusWheel) {
                        setTimeout(() => {
                            this.goToStep(this.pageSteps.spinner);
                        }, 500);
                    } else {
                        this.mintBrushesPage = 1;
                        this.mintAmount = 0;
                        this.mintBrushes = [];
                        this.mintBrushesSelected = [];
                        this.goToStep(this.pageSteps.initial);
                        this.getPageInfo();
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onSpinResult(result) {
        if (result) {
            setTimeout(() => {
                this.goToStep(this.pageSteps.initial);
                this.getPageInfo();
            }, 5000);

        }
    }

    onMintBrushChange(e, item) {
        if (!this.mintBrushesSelected.length && this.mintAmount) {
            this.mintBrushesSelected.push(item);
        } else {
            if (this.mintAmount && ((this.mintBrushesSelected.length !== this.mintAmount) || !e.target.checked)) {
                let isElementExist = false;
                this.mintBrushesSelected.forEach(el => {
                    if (item.uuid === el.uuid) {
                        isElementExist = true;
                    }
                });
                if (isElementExist) {
                    this.mintBrushesSelected = this.mintBrushesSelected.filter(brush => brush.uuid !== item.uuid);
                } else {
                    this.mintBrushesSelected.push(item);
                }
            } else {
                e.target.checked = false;
                this.popupComponent.showPopupError({text: `${this.languageService.getString('youCanChooseOnly')} ${this.mintAmount} ${this.mintAmount > 1 ? this.languageService.getString('images') : this.languageService.getString('image')}`})
            }
        }
    }

    showUnfinishedWork() {
        this.showGeneration();
    }

    showGeneration() {
        this.goToStep(this.pageSteps.generation);
        this.checkoutService.getGenerateInfo().subscribe(data => {
            this.setGenerateSteepInfo(data);
        }, () => {
            this.goToStep(this.pageSteps.initial);
            this.getPageInfo();
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setGenerateSteepInfo(data) {
        try {
            if (data.status) {
                this.generateDefaultImage = data.imageDefault;
                this.generateAllImagesAmount = data.total;
                this.generatedImagesAmount = data.current;
                this.genBrushes = data.images;
                document.getElementById('keyWords').innerText = data.keywords;
                (document.getElementById('keyWords') as HTMLTextAreaElement).value = data.keywords;
            } else {
                this.popupComponent.showPopupError({text: data.message});
                this.goToStep(this.pageSteps.initial);
                this.getPageInfo();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
            this.goToStep(this.pageSteps.initial);
            this.getPageInfo();
        }
    }

    goToStep(step) {
        // document.querySelector('.login__content').scrollTop = 0;
        this.step = step;
        document.querySelector('.login__content').scrollTo({top: 0, behavior: 'smooth'});
    }

    generateOne() {
        const text = (document.getElementById('keyWords') as HTMLTextAreaElement).value;
        this.checkoutService.generateOne(text).subscribe(data => {
            try {
                if (data.status) {
                    this.generateDefaultImage = data.link;
                    this.generatedImagesAmount = +this.generatedImagesAmount + 1;
                    this.genBrushes = data.images;
                    if (this.genBrushes.length === this.generateAllImagesAmount) {
                        //this.popupComponent.showPopupSuccess({text: data.message});
                        this.generationIsFinished = true;
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {

            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onBuyNew() {
        this.generationIsFinished = false;
        this.goToStep(this.pageSteps.initial);
        this.getPageInfo();
    }

    generateAll() {
        const text = (document.getElementById('keyWords') as HTMLTextAreaElement).value;
        this.checkoutService.generateAll(text).subscribe(data => {
            try {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.goToStep(this.pageSteps.initial);
                    this.getPageInfo();
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {

            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setDefaultKeyWords() {
        this.checkoutService.generateText().subscribe(data => {
            try {
                if (data.status) {
                    document.getElementById('keyWords').innerText = data.result;
                    (document.getElementById('keyWords') as HTMLTextAreaElement).value = data.result;
                } else {
                    //this.popupComponent.showPopupError({text: data.message});
                    document.getElementById('keyWords').innerText = this.defaultKeyWords;
                    (document.getElementById('keyWords') as HTMLTextAreaElement).value = this.defaultKeyWords;
                }
            } catch (e) {
                document.getElementById('keyWords').innerText = this.defaultKeyWords;
                (document.getElementById('keyWords') as HTMLTextAreaElement).value = this.defaultKeyWords;
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
            document.getElementById('keyWords').innerText = this.defaultKeyWords;
            (document.getElementById('keyWords') as HTMLTextAreaElement).value = this.defaultKeyWords;
        }, () => {
            this.loaderService.hide();
        });
    }

    isGenerateAll() {
        //instant manual
        if (this.checkOutForm && this.checkOutForm.controls.generation) {
            return this.checkOutForm.controls.generation.value === 'instant';
        }
    }

    resetToGenerateAll() {
        this.checkOutForm.controls.generation.setValue('instant');
    }

    showVerifyPopup(item) {
        document.body.classList.add('hide-menu');
        this.verifyPopup.show = true;
        this.verifyPopup.id = item.id;
    }

    onDeleteCard(card) {
        const lastFour = card.last_four;
        const cardId = card.id;
        const text = this.languageService.getString('messagesData', 'confirmDeletingCard') + ' ' + this.languageService.getString('messagesData', 'endingIn') + ' ' + lastFour + '?';
        this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            if (status) {
                this.cardsService.removeBankAccount(cardId).subscribe(data => {
                    try {
                        if (data.status === true) {
                            this.cards = this.cards.filter(card => card.id !== cardId);
                        } else if (data.status === false) {
                            this.popupComponent.showPopupError({text: data.message});
                        } else {
                            this.loaderService.hideAndShowTryAgain();
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            }
        });
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    showAdditionContent() {
        return this.contentDisplayingService.showAdditionContent();
    }

    getLanguageString(val) {
        return this.languageService.getString(val);
    }

    onShowLibraryBigImagePopup(slide) {
        // document.body.classList.add('hide-menu');
        // document.querySelector('.login__forms').classList.add('blur');
        this.showLibraryBigImagePopup = true;
        this.libraryBigImagePopupData = {
            data: slide
        }
    }

    onPopupLibraryBigImageClose() {
        this.showLibraryBigImagePopup = false;
        // document.querySelector('.login__forms').classList.remove('blur');
    }

}

