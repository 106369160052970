import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-button-loading',
    templateUrl: './button-loading.component.html',
    styleUrls: ['./button-loading.component.scss']
})
export class ButtonLoadingComponent implements OnInit {

    @Input() type;
    @Input() styleWidth;
    @Input() showLoader = false;
    @Input() disabledInner: boolean = false;
    @Output('onClick') emitClick: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    // toggleVariants() {
    //     this.ishiden =  !this.ishiden;
    // }
    //
    // getClasses() {
    //     if (this.type == 'close') {
    //         return 'btn_close';
    //     }
    // }

    handleClick() {
        this.emitClick.emit();
    }

}
