<div class="saved">
    <div class="saved__list">
        <div *ngFor="let card of cards" class="form-simple__item card-selection__row">
            <input [id]="card.id"
                   [type]="'radio'"
                   class="form-simple__radio"
                   [value]="card.id"
                   name="email"
                   (change)="changeCardMethod(card)"
                   [checked]="activeMethod === card"
                   autocomplete="off">
            <label [for]="card.id">{{card.description}} - {{card.last_four}}</label>
            <button class="card-selection__remove"
                    [ngStyle]="{visibility: card.verify !== 1 ? 'visible' : 'hidden',
                                                                pointerEvents: card.verify !== 1 ? 'auto' : 'none'}"
                    (click)="showVerifyPopup(card)"
                    title="Verify">
                {{'verify' | languageTranslate}}
            </button>
            <button class="card-selection__delete"
                    (click)="onDeleteCard(card)"
                    title="{{'delete' | languageTranslate}}">
                {{'delete' | languageTranslate}}
            </button>
        </div>
    </div>
</div>
