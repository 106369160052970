import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import {APP_DATE_FORMATS, SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {NgxDateRangeModule} from "ngx-daterange";
import {CustomDateAdapter} from "../adapters/custom-date-adapter";
import {ShopComponent} from "./shop/shop.component";
import {ContactsFormComponent} from "./contacts-form/contacts-form.component";
import {SupportComponent} from "./support/support.component";
import {PopupRewardsGameFormComponent} from "./shop/popup-rewards-game-form/popup-rewards-game-form.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {PopupShopComponent} from "./shop/popup-shop/popup-shop.component";
import {PerksComponent} from "./perks/perks.component";
import {AccountComponent} from "./account/account.component";
import {TestCashAppComponent} from "./test-cash-app/test-cash-app.component";
import {CommissionComponent} from "./commission/commission.component";
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {SenetproComponent} from "./promo/senetpro/senetpro.component";
import {PartnerComponent} from "./partner/partner.component";
import {LoginContactsFormComponent} from "./login/login-contacts-form/login-contacts-form.component";
import {ResetPasswordComponent} from "./reset-password/reset-password.component";
import {VerifyEmailComponent} from "./verify-email/verify-email.component";

@NgModule({
    declarations: [
        DatepickerCustomHeaderExample,
        ExampleHeader,

        MainComponent,
        LoginComponent,
        LoginContactsFormComponent,

        HomeComponent,

        ShopComponent,
        PopupRewardsGameFormComponent,
        PopupShopComponent,

        CheckoutComponent,
        PerksComponent,
        ContactsFormComponent,
        SupportComponent,
        AccountComponent,
        TestCashAppComponent,
        // CheckoutCashAppComponent,
        CommissionComponent,
        TermsAndConditionsComponent,
        SenetproComponent,
        PartnerComponent,
        ResetPasswordComponent,
        VerifyEmailComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        NgxCurrencyModule,
        DemoMaterialModule,
        NgxDateRangeModule
    ],
    providers: [
        FocusMonitor,
        Platform,
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {provide: DateAdapter, useClass: CustomDateAdapter }
    ],
    exports: [
        LoginComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader
    ],
    entryComponents: [
        LoginComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
