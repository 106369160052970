/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkout-charge-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/language/language-translate.pipe";
import * as i3 from "../../modules/language/language.service";
import * as i4 from "@angular/common";
import * as i5 from "../button-loading/button-loading.component.ngfactory";
import * as i6 from "../button-loading/button-loading.component";
import * as i7 from "./checkout-charge-popup.component";
import * as i8 from "../../service/loader.service";
import * as i9 from "../../modules/currency/currency.service";
import * as i10 from "../../service/cashapp-pay.service";
import * as i11 from "../popup/popup.component";
var styles_CheckoutChargePopupComponent = [i0.styles];
var RenderType_CheckoutChargePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckoutChargePopupComponent, data: {} });
export { RenderType_CheckoutChargePopupComponent as RenderType_CheckoutChargePopupComponent };
function View_CheckoutChargePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "p", [["class", "nft-terms__text"], ["style", "line-height: 1.2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["style", "font-family: b-montserrat, sans-serif"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵted(6, null, [" ", " ", " ", ". "])), i1.ɵpod(7, { $innerVariable: 0 }), i1.ɵpad(8, 1), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpod(13, { $innerVariable: 0 }), i1.ɵpad(14, 1), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("click")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("confirm")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 9).transform("nftPopupData", _ck(_v, 8, 0, _ck(_v, 7, 0, "buttonToDepositPlacement")))); var currVal_3 = _co.formatCurrency(_co.popupData.inputAmount); var currVal_4 = i1.ɵunv(_v, 6, 2, i1.ɵnov(_v, 10).transform("now")); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 15).transform("nftPopupData", _ck(_v, 14, 0, _ck(_v, 13, 0, "allSalesAreFinal")))); _ck(_v, 12, 0, currVal_5); }); }
function View_CheckoutChargePopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "p", [["class", "nft-terms__text"], ["style", "line-height: 1.2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["style", "font-family: b-montserrat, sans-serif"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵted(6, null, [" ", " ", " ", ". "])), i1.ɵpod(7, { $innerVariable: 0 }), i1.ɵpad(8, 1), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpod(13, { $innerVariable: 0 }), i1.ɵpad(14, 1), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("click")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("cashAppPay")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 9).transform("nftPopupData", _ck(_v, 8, 0, _ck(_v, 7, 0, "buttonToDepositPlacement")))); var currVal_3 = _co.formatCurrency(_co.popupData.inputAmount); var currVal_4 = i1.ɵunv(_v, 6, 2, i1.ɵnov(_v, 10).transform("now")); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 15).transform("nftPopupData", _ck(_v, 14, 0, _ck(_v, 13, 0, "allSalesAreFinal")))); _ck(_v, 12, 0, currVal_5); }); }
function View_CheckoutChargePopupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "nft-info__btn button"], ["style", "margin-top: 1rem; margin-bottom: -1rem;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowCashApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("confirm")); _ck(_v, 1, 0, currVal_0); }); }
function View_CheckoutChargePopupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_5)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp); _ck(_v, 1, 0, currVal_0); }, null); }
function View_CheckoutChargePopupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-button-loading", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDepositConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonLoadingComponent_0, i5.RenderType_ButtonLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i6.ButtonLoadingComponent, [], { showLoader: [0, "showLoader"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(0, i2.LanguageTranslatePipe, [i3.LanguageService])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupData.loader; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("confirm")); _ck(_v, 2, 0, currVal_1); }); }
function View_CheckoutChargePopupComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "payment-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "cash-app-pay"]], null, null, null, null, null))], null, null); }
function View_CheckoutChargePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "investments-popup"]], [[2, "is-active", null], [2, "is-blurred", null], [2, "is-accent", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "investments-popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "nft-info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0, null, View_CheckoutChargePopupComponent_4)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "nft-info__btns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_7)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp); _ck(_v, 7, 0, currVal_4); var currVal_5 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard); _ck(_v, 11, 0, currVal_5); var currVal_6 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp); _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupData; var currVal_1 = false; var currVal_2 = false; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_CheckoutChargePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageInput: 0 }), i1.ɵqud(671088640, 2, { termsAccept: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckoutChargePopupComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupData; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CheckoutChargePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-checkout-charge-popup", [], null, null, null, View_CheckoutChargePopupComponent_0, RenderType_CheckoutChargePopupComponent)), i1.ɵdid(1, 9027584, null, 0, i7.CheckoutChargePopupComponent, [i8.LoaderService, i3.LanguageService, i1.ChangeDetectorRef, i9.CurrencyService, i10.CashappPayService, i11.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckoutChargePopupComponentNgFactory = i1.ɵccf("app-checkout-charge-popup", i7.CheckoutChargePopupComponent, View_CheckoutChargePopupComponent_Host_0, { show: "show", popupData: "popupData" }, { close: "close", emitDepositConfirm: "emitDepositConfirm" }, []);
export { CheckoutChargePopupComponentNgFactory as CheckoutChargePopupComponentNgFactory };
