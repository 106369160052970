import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../animations/router.animations";
import {Router} from "@angular/router";
import {RouterPaths} from "../../config/router-paths.model";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {AppVersionService} from '../../service/app-version/appVersion.service';
import {PosSettingsService} from "../../service/pos-settings.service";
import {ReferralBonusService} from "../../service/referralBonus.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class HomeComponent implements OnInit {
    partnerLogo = this.partnerService.getPartnerLogo() ? this.partnerService.getPartnerLogo() : '../assets/images/logo.png';
    // partnerLogo = '';
    menu = false;
    RouterPaths = RouterPaths;

    constructor(private router: Router,
                private appVersionService: AppVersionService,
                private paginationScrollService: PaginationScrollService,
                private posSettingsService: PosSettingsService,
                private contentDisplayingService: ContentDisplayingService,
                private partnerService: PartnerService,
                private referralBonusService: ReferralBonusService,
                private authenticationService: AuthenticationService) {}


    ngOnInit() {
        // this.partnerLogo = this.partnersService.getLogo();
        // this.partnersService.updatePartnerInfo();
    }

    isCheckOutShown() {
        return this.posSettingsService.getCheckOutPageVisibility();
    }

    isContactPage() {
        return this.router.url.includes(this.RouterPaths.contacts);
    }

    isTermsPage() {
        return this.router.url.includes(this.RouterPaths.termsAndConditions);
    }

    isPerksPage() {
        return this.router.url.includes(this.RouterPaths.perks);
    }

    isRewardsPage() {
        return this.router.url.includes(this.RouterPaths.commission);
    }

    showRewardsBlock() {
        return !this.isContactPage() && !this.isTermsPage() && !this.isPerksPage() && !this.isRewardsPage();
    }

    onLogout() {
        this.authenticationService.logout();
        this.router.navigate(['/']);
    }

    onContentScroll($event) {
        this.paginationScrollService.emitContentScroll($event);
    }

    upgradeApp() {
        this.appVersionService.upgradeApp();
    }

    isUserLogged() {
        return this.authenticationService.isUserLogged();
    }

    showReferral() {
        return this.referralBonusService.showReferralPage();
    }

    isSupportShown() {
        return this.posSettingsService.getSupportVisibility();
    }

    showAdditionContent() {
        return this.contentDisplayingService.showAdditionContent();
    }
}
