import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {PopupComponent} from "../popup/popup.component";
import {LanguageService} from "../../modules/language/language.service";
import {LoaderService} from "../../service/loader.service";
import {CardsService} from "../../service/cards.service";
import {ScrollToService} from "../../service/scrollTo.service";

@Component({
    selector: 'app-cards-selection',
    templateUrl: './cards-selection.component.html',
    styleUrls: ['./cards-selection.component.scss']
})
export class CardsSelectionComponent implements OnInit {
    @Input() cards;
    @Input() verifiedCardId;
    @Output('changeMethod') changeMethod: EventEmitter<any> = new EventEmitter();
    @Output('showVerifyPopup') emitShowVerifyPopup: EventEmitter<any> = new EventEmitter();
    @Output('closeVerifyPopup') closeVerifyPopup: EventEmitter<any> = new EventEmitter();

    verifyPopup = {
        show: false,
        id: null
    };
    activeMethod;

    constructor(private popupComponent: PopupComponent,
                private languageService: LanguageService,
                private scrollToService: ScrollToService,
                private cardsService: CardsService,
                private loaderService: LoaderService) {}

    ngOnInit() {
        if (this.cards && this.cards.length) {
            this.activeMethod = this.cards[this.cards.length-1];
            this.scrollToLastMethod();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log(changes)
    }

    onCloseVerifyPopup(e) {
        document.body.classList.remove('hide-menu');
        if (e) {
            this.cards.forEach(card => {
                if (card.id === this.verifyPopup.id) {
                    card.verify = 1;
                }
            });
        }
        this.verifyPopup.show = false;
        this.verifyPopup.id = null;
    }

    showVerifyPopup(item) {
        this.emitShowVerifyPopup.emit(item);
    }

    onDeleteCard(card) {
        const lastFour = card.last_four;
        const cardId = card.id;
        const text = this.languageService.getString('messagesData', 'confirmDeletingCard') + ' ' + this.languageService.getString('messagesData', 'endingIn') + ' ' + lastFour + '?';
        this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            if (status) {
                this.cardsService.removeBankAccount(cardId).subscribe(data => {
                    try {
                        if (data.status === true) {
                            this.cards = this.cards.filter(card => card.id !== cardId);
                        } else if (data.status === false) {
                            this.popupComponent.showPopupError({text: data.message});
                        } else {
                            this.loaderService.hideAndShowTryAgain();
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            }
        });
    }

    changeCardMethod(card) {
        this.changeMethod.emit(card);
    }

    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

}
