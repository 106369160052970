/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cards-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../modules/language/language-translate.pipe";
import * as i4 from "../../modules/language/language.service";
import * as i5 from "./cards-selection.component";
import * as i6 from "../popup/popup.component";
import * as i7 from "../../service/scrollTo.service";
import * as i8 from "../../service/cards.service";
import * as i9 from "../../service/loader.service";
var styles_CardsSelectionComponent = [i0.styles];
var RenderType_CardsSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardsSelectionComponent, data: {} });
export { RenderType_CardsSelectionComponent as RenderType_CardsSelectionComponent };
function View_CardsSelectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "form-simple__item card-selection__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["autocomplete", "off"], ["class", "form-simple__radio"], ["name", "email"]], [[8, "id", 0], [8, "type", 0], [8, "value", 0], [8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.changeCardMethod(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " - ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["class", "card-selection__remove"], ["title", "Verify"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showVerifyPopup(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { visibility: 0, pointerEvents: 1 }), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "card-selection__delete"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeleteCard(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService]), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(0, i3.LanguageTranslatePipe, [i4.LanguageService])], function (_ck, _v) { var currVal_7 = _ck(_v, 7, 0, ((_v.context.$implicit.verify !== 1) ? "visible" : "hidden"), ((_v.context.$implicit.verify !== 1) ? "auto" : "none")); _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.id; var currVal_1 = "radio"; var currVal_2 = _v.context.$implicit.id; var currVal_3 = (_co.activeMethod === _v.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_4); var currVal_5 = _v.context.$implicit.description; var currVal_6 = _v.context.$implicit.last_four; _ck(_v, 3, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("verify")); _ck(_v, 8, 0, currVal_8); var currVal_9 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("delete")), ""); _ck(_v, 10, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("delete")); _ck(_v, 12, 0, currVal_10); }); }
export function View_CardsSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "saved"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "saved__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardsSelectionComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cards; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CardsSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cards-selection", [], null, null, null, View_CardsSelectionComponent_0, RenderType_CardsSelectionComponent)), i1.ɵdid(1, 638976, null, 0, i5.CardsSelectionComponent, [i6.PopupComponent, i4.LanguageService, i7.ScrollToService, i8.CardsService, i9.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardsSelectionComponentNgFactory = i1.ɵccf("app-cards-selection", i5.CardsSelectionComponent, View_CardsSelectionComponent_Host_0, { cards: "cards", verifiedCardId: "verifiedCardId" }, { changeMethod: "changeMethod", emitShowVerifyPopup: "showVerifyPopup", closeVerifyPopup: "closeVerifyPopup" }, []);
export { CardsSelectionComponentNgFactory as CardsSelectionComponentNgFactory };
