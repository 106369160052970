/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-loading.component";
var styles_ButtonLoadingComponent = [i0.styles];
var RenderType_ButtonLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonLoadingComponent, data: {} });
export { RenderType_ButtonLoadingComponent as RenderType_ButtonLoadingComponent };
function View_ButtonLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "loader-btn"]], null, null, null, null, null))], null, null); }
export function View_ButtonLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "button", [["class", "button"]], [[2, "is-loading", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { minWidth: 0, width: 1 }), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "loader-btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLoadingComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.styleWidth ? _ck(_v, 3, 0, (_co.styleWidth + "rem"), (_co.styleWidth + "rem")) : i1.ɵEMPTY_MAP); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.showLoader; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoader; var currVal_1 = (_co.disabledInner || _co.showLoader); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ButtonLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-loading", [], null, null, null, View_ButtonLoadingComponent_0, RenderType_ButtonLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonLoadingComponentNgFactory = i1.ɵccf("app-button-loading", i3.ButtonLoadingComponent, View_ButtonLoadingComponent_Host_0, { type: "type", styleWidth: "styleWidth", showLoader: "showLoader", disabledInner: "disabledInner" }, { emitClick: "onClick" }, ["*"]);
export { ButtonLoadingComponentNgFactory as ButtonLoadingComponentNgFactory };
