import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-popup-wrapper',
    templateUrl: './popup-wrapper.component.html',
    styleUrls: ['./popup-wrapper.component.scss']
})
export class PopupWrapperComponent implements OnInit {
    @Input() show: boolean;
    @Input() container: boolean;
    @Output('onClose') emitClose: EventEmitter<any> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    closePopup() {
        this.emitClose.emit();
    }

}
