<div class="login__forms" [class.is-blurred]="">
    <div class="login__tab login__tab--no-rewards-block">

        <ng-container *ngIf="showAdditionalContent()">
            <app-balance-heading></app-balance-heading>
        </ng-container>

        <div class="page-block" [class.page-block--no-mt]="!showAdditionalContent()">
            <div class="gl-page-title gl-page-title--icon">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                EARN-->
                {{'earn' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity">
<!--                The commission is settled once per week, always on Mondays. Available Balance is your reward that you can claim. Running Balance is a live tally and can change throughout the week. Once your Available Balance is issued, it is recommended that you use it within a week to make sure your current Running Balance does not change it the following week.-->
                {{'earnTitle' | languageTranslate}}
            </div>
        </div>

        <div class="page-block">
            <div class="gl-page-title gl-page-title--icon">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                Available Balance-->
                {{'availableBalance' | languageTranslate}}
                <span class="gl-page-title__balance">{{formatCurrency(availableBalance)}}</span>
            </div>
            <p class="gl-subtitle gl-subtitle--opacity" *ngIf="availableBalance < 0">
<!--                Since your last commission, there were more rewards sent than money spent. No worries. You are never responsible for it. We cover it all for you. The next commission will be sent as soon as your balance become positive.-->
                {{'availableBalanceNegativeText' | languageTranslate}}
            </p>
            <div class="gl-subtitle gl-subtitle--opacity">
<!--                You can use this balance now. Remember next week your Running Balance might increase or decrease it.-->
                {{'availableBalanceTitle' | languageTranslate}}
            </div>

            <div class="page__form">
                <form [formGroup]="form" (ngSubmit)="onSubmitPayout()" class="form-simple form-simple--primary">
                    <div class="form-simple__item" *ngIf="balanceTypes && balanceTypes.length">
                        <mat-form-field>
                            <mat-select #methodsSelect
                                        formControlName="type"
                                        [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                                <mat-select-trigger>
                                    {{methodsSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let method of balanceTypes" [value]="method">
                                    {{method.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-simple__item form-simple__item--amount">
                        <input mask="separator.0"
                               thousandSeparator=","
                               formControlName="amount"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'amount'}]}}"
                               [type]="'tel'"
                               (input)="onInputAmount()"
                               class="form-simple__input"
                               autocomplete="off">
                        <span>.00</span>
                        <div [class.is-active]="moreThenAvailablePayout" class="form-simple__available">
                            <p *ngIf="isAvailableBalance(); else isNegativeBalance">
                                {{'available' | languageTranslate}} {{formatCurrency(availableBalance)}}
                            </p>
                            <ng-template #isNegativeBalance>
                                <p>{{'available' | languageTranslate}} {{formatCurrency(0)}}</p>
                            </ng-template>
                        </div>
                    </div>


                    <div class="form-simple__btns">
                        <button style="width: 100%"
                                class="button"
                                [disabled]="!+form.controls.amount.value">
<!--                            SETTLE-->
                            {{'settle' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="page-block">
            <div class="gl-page-title gl-page-title--icon">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                Running balance-->
                {{'runningBalance' | languageTranslate}}
                <span class="gl-page-title__balance">{{formatCurrency(balance)}}</span>
            </div>
            <p class="gl-subtitle gl-subtitle--opacity">
<!--                Thank you and welcome to our Referral Program where you can earn {{commission}}%-->
<!--                commission by sharing our link with your friends and family.-->
<!--                When making a referral, please mention the website {{partnerDomain}}-->
<!--                together with your personal PROMO Code. The table below shows-->
<!--                your commission inflows and outflows. Your commission is paid-->
<!--                out weekly on the Net of all earned income.-->
                {{'runningBalanceTitle' | languageTranslate: [{commission: commission}, {domain: partnerDomain}]}}
            </p>

            <div class="commission__text-block">
                <div>
                    <div style="text-align: center; margin-bottom: 1rem">
                        <h2 class="commission__title">
<!--                            SITE-->
                            {{'site' | languageTranslate}}
                        </h2>
                        <span style="user-select: text" class="commission__text commission__text--accent commission__link">{{site}}</span>
                        <h2 class="commission__title">
<!--                            PROMO CODE-->
                            {{'promoCode' | languageTranslate}}
                        </h2>
                        <span style="user-select: text" class="commission__text commission__text--accent">{{promoCode}}</span>
                    </div>
                </div>
            </div>

            <p class="gl-subtitle" style="display: none">
<!--                Easily refer anybody by simply entering their phone below and clicking the Refer button. A Text message will be sent on your behalf with the link and your personal Promo Code.-->
                {{'referAnybodyInstruction' | languageTranslate}}
            </p>

            <div class="page__form" style="display: none">
                <form [formGroup]="formRefer" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                    <div class="form-simple__item">
                        <input formControlName="firstLastName"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'firstLastName'}]}}"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>
                    <div class="form-simple__item">
                        <input [mask]="phoneMask"
                               formControlName="phone"
                               placeholder="{{'formData' | languageTranslate: [{$innerVariable: 'phone'}]}}"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button style="width: 100%"
                                class="button"
                                [disabled]="formRefer.invalid">
<!--                            REFER-->
                            {{'refer' | languageTranslate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="page-block">
            <div class="gl-page-title gl-page-title--icon">
                <svg>
                    <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                </svg>
<!--                Sales History-->
                {{'salesHistory' | languageTranslate}}
            </div>
            <div class="gl-subtitle gl-subtitle--opacity">
<!--                The table below shows historical records of all your sales and all commissions ever issued to you.-->
                {{'salesHistoryTitle' | languageTranslate}}
            </div>


            <div class="gl-subtitle gl-subtitle--opacity c-accent" style="text-transform: uppercase; opacity: 1">
<!--                Transaction History-->
                {{'transactionHistory' | languageTranslate}}
            </div>
            <div class="dropdown__body">
                <table class="commission-history">
                    <tr class="commission-history__head">
                        <th>
<!--                            DATE-->
                            {{'tableData' | languageTranslate: [{$innerVariable: 'date'}]}}
                        </th>
                        <th>
<!--                            CREDIT-->
                            {{'tableData' | languageTranslate: [{$innerVariable: 'credit'}]}}
                        </th>
                        <th>
<!--                            DEBIT-->
                            {{'tableData' | languageTranslate: [{$innerVariable: 'debit'}]}}
                        </th>
                        <th>
<!--                            BAL-->
                            {{'tableData' | languageTranslate: [{$innerVariable: 'balanceShort'}]}}
                        </th>
                        <th>
<!--                            COM {{commission}}%-->
                            {{'tableData' | languageTranslate: [{$innerVariable: 'commissionShort'}]}} {{commission}}%
                        </th>
                    </tr>
                    <tr *ngFor="let action of commissionData.items" class="commission-history__item js-commission-item">
                        <td>{{getDate(action.created) | date: 'MM/dd/yy'}}</td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.credit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.debit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="action.balance"></app-financial-info-item></td>
                        <td class="accent"><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.settlement)"></app-financial-info-item></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
