import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonComponent} from "./button/button.component";
import {PopupComponent} from "./popup/popup.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {LoaderComponent} from "./loader/loader.component";
import {MatSelectModule} from "@angular/material";
import {DemoMaterialModule} from "../material.module";
import {GetRewardsComponent} from "./get-rewards/get-rewards.component";
import {BalanceHeadingComponent} from "./balance-heading/balance-heading.component";
import {PopupDebitCardComponent} from "./popup-debit-card/popup-debit-card.component";
import {IframePopupComponent} from "./iframe-popup/iframe-popup.component";
import {EllipsisLoaderComponent} from "./loaders/ellipsis-loader/ellipsis-loader.component";
import {SenetproTransactionsComponent} from "./senetpro-transactions/senetpro-transactions.component";
import {PopupSenetproTransactionsComponent} from "./popup-senetpro-transactions/popup-senetpro-transactions.component";
import {PopupPrizesSenetproConfirmComponent} from "./popup-prizes-senetpro-confirm/popup-prizes-senetpro-confirm.component";
import {PopupChannelsComponent} from "./popup-channels/popup-channels.component";
import {VerifyCashappTagComponent} from "./verify-cashapp-tag/verify-cashapp-tag.component";
import {PopupGenerateComponent} from "./popup-generate/popup-generate.component";
import {NftLibraryComponent} from "./nft-library/nft-library.component";
import {InvestmentsNftPopupComponent} from "../pages/investments-nft-popup/investments-nft-popup.component";
import {RouterModule} from "@angular/router";
import {FinancialInfoItemComponent} from "./financial-info-item/financial-info-item.component";
import {PopupContactUsComponent} from "./popup-contact-us/popup-contact-us.component";
import {PopupWrapperComponent} from "./popup-wrapper/popup-wrapper.component";
import {PopupVerifyCardComponent} from "./popup-verify-card/popup-verify-card.component";
import {PopupWithdrawCheckComponent} from "./popup-withdraw-check/popup-withdraw-check.component";
import {LanguageTranslatePipe} from "../modules/language/language-translate.pipe";
import {PopupChangeUserInfoComponent} from "./popup-change-user-info/popup-change-user-info.component";
import {PopupVerifyPhoneComponent} from "./popup-verify-phone/popup-verify-phone.component";
import {SpinnerComponent} from "./spinner/spinner.component";
import {CheckoutChargePopupComponent} from "./checkout-charge-popup/checkout-charge-popup.component";
import {PopupMoreImagesComponent} from "./popup-more-images/popup-more-images.component";
import {PopupWithdrawBankComponent} from "./popup-withdraw-bank/popup-withdraw-bank.component";
import {PopupBigImageComponent} from "./popup-big-image/popup-big-image.component";
import {CardsSelectionComponent} from "./cards-selection/cards-selection.component";
import {ButtonLoadingComponent} from "./button-loading/button-loading.component";

export const APP_DATE_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: {month: 'numeric', year: 'numeric', day: 'numeric'},
        yearLabel: {year: 'numeric'},
        monthLabel: {month: 'short'}
    }
};

@NgModule({
    declarations: [
        ButtonComponent,
        PopupComponent,
        LoaderComponent,
        GetRewardsComponent,
        BalanceHeadingComponent,
        PopupDebitCardComponent,
        IframePopupComponent,
        EllipsisLoaderComponent,
        SenetproTransactionsComponent,
        PopupSenetproTransactionsComponent,
        PopupPrizesSenetproConfirmComponent,
        PopupChannelsComponent,
        VerifyCashappTagComponent,
        PopupGenerateComponent,
        PopupVerifyPhoneComponent,
        NftLibraryComponent,
        InvestmentsNftPopupComponent,
        FinancialInfoItemComponent,
        PopupContactUsComponent,
        PopupWrapperComponent,
        PopupVerifyCardComponent,
        PopupWithdrawCheckComponent,
        PopupWithdrawBankComponent,
        PopupChangeUserInfoComponent,
        LanguageTranslatePipe,
        SpinnerComponent,
        CheckoutChargePopupComponent,
        PopupMoreImagesComponent,
        PopupBigImageComponent,
        CardsSelectionComponent,
        ButtonLoadingComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatSelectModule,
    RouterModule
  ],
    providers: [
    ],
    entryComponents: [
        PopupGenerateComponent,
        PopupVerifyPhoneComponent
    ],
  exports: [
    ButtonComponent,
    PopupComponent,
    LoaderComponent,
    GetRewardsComponent,
    BalanceHeadingComponent,
    PopupDebitCardComponent,
    IframePopupComponent,
    EllipsisLoaderComponent,
    SenetproTransactionsComponent,
    PopupSenetproTransactionsComponent,
    PopupPrizesSenetproConfirmComponent,
    PopupChannelsComponent,
    VerifyCashappTagComponent,
    PopupGenerateComponent,
    PopupVerifyPhoneComponent,
    NftLibraryComponent,
    InvestmentsNftPopupComponent,
    FinancialInfoItemComponent,
    PopupContactUsComponent,
    PopupWrapperComponent,
    PopupVerifyCardComponent,
    PopupWithdrawCheckComponent,
    PopupWithdrawBankComponent,
    LanguageTranslatePipe,
    PopupChangeUserInfoComponent,
    SpinnerComponent,
    CheckoutChargePopupComponent,
    PopupMoreImagesComponent,
    PopupBigImageComponent,
    CardsSelectionComponent,
    ButtonLoadingComponent
  ]
})
export class SharedModule { }
