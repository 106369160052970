<div *ngIf="popupData"
     class="investments-popup"
     [class.is-active]="popupData"
     [class.is-blurred]="false"
     [class.is-accent]="false">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>

        <div class="nft-info">
            <ng-template [ngIf]="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard">
                <p class="nft-terms__text" style="line-height: 1.2">
                    <!--                    Click-->
                    {{'click' | languageTranslate}}
                    <span style="font-family: b-montserrat, sans-serif">{{'confirm' | languageTranslate}}</span>
                    <!--                    button below to deposit {{popupData.inputAmount | currency : 'USD' : 'symbol'}} now.-->
                    {{'nftPopupData' | languageTranslate: [{$innerVariable: 'buttonToDepositPlacement'}]}} {{formatCurrency(popupData.inputAmount)}} {{'now' | languageTranslate}}.
                    <br>
                    <!--                    All sales are final.-->
                    {{'nftPopupData' | languageTranslate: [{$innerVariable: 'allSalesAreFinal'}]}}
                </p>
            </ng-template>

            <ng-template [ngIf]="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp">
                <p class="nft-terms__text" style="line-height: 1.2">
                    <!--                    Click-->
                    {{'click' | languageTranslate}}
                    <span style="font-family: b-montserrat, sans-serif">{{'cashAppPay' | languageTranslate}}</span>
                    <!--                    button below to deposit {{popupData.inputAmount | currency : 'USD' : 'symbol'}} now.-->
                    {{'nftPopupData' | languageTranslate: [{$innerVariable: 'buttonToDepositPlacement'}]}} {{formatCurrency(popupData.inputAmount)}} {{'now' | languageTranslate}}.
                    <br>
                    <!--                    All sales are final.-->
                    {{'nftPopupData' | languageTranslate: [{$innerVariable: 'allSalesAreFinal'}]}}
                </p>
            </ng-template>

            <ng-template>
                <button (click)="onShowCashApp()"
                        *ngIf="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp"
                        style="margin-top: 1rem; margin-bottom: -1rem;"
                        class="nft-info__btn button">
                    {{'confirm' | languageTranslate}}
                </button>
            </ng-template>

            <div class="nft-info__btns">
<!--                <button *ngIf="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard"-->
<!--                        (click)="onDepositConfirm()"-->
<!--                        class="nft-info__btn button">-->
<!--                    {{'confirm' | languageTranslate}}-->
<!--                </button>-->
                <app-button-loading *ngIf="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard"
                                    [showLoader]="popupData.loader"
                                    (click)="onDepositConfirm()">
                    {{'confirm' | languageTranslate}}
                </app-button-loading>

                <div id="payment-form" *ngIf="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp">
                    <div id="cash-app-pay"></div>
                </div>
            </div>
        </div>
    </div>
</div>
