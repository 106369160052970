import { OnInit, ViewContainerRef } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import { GAME_VENDOR_LINK, GAME_VENDORS_FIREKIRIN, GAME_VENDORS_GOLDENDRAGON, GAME_VENDORS_JUWA, GAME_VENDORS_PUMA, GAME_VENDORS_RIVER, GAME_VENDORS_SENET, GAME_VENDORS_ULTRAPANDA, GAME_VENDORS_VBLINK } from "../../config/gameVendors";
import { SHOP_POPUP_TYPE } from "./shop.config";
import { POPUP_DEBIT_CARD_TYPE } from "../../shared/popup-debit-card/popup-debit-card.config";
import { COUNTRIES_SHORT } from "../../config/countries.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/router";
import * as i3 from "../../service/shop.service";
import * as i4 from "../../service/loader.service";
import * as i5 from "../../service/games/gamesProvider.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../service/point.service";
import * as i8 from "../../service/wallet.service";
import * as i9 from "../../modules/language/language.service";
import * as i10 from "../../service/pos-settings.service";
import * as i11 from "../../service/content-displaying.service";
import * as i12 from "../../modules/country/country.service";
import * as i13 from "../../service/states.service";
import * as i14 from "../../modules/currency/currency.service";
import * as i15 from "../../shared/popup/popup.component";
var ShopComponent = /** @class */ (function () {
    function ShopComponent(formBuilder, router, activatedRoute, shopService, loaderService, gamesProviderService, sanitizer, pointService, walletService, languageService, posSettingsService, contentDisplayingService, countryService, statesService, currencyService, popupComponent) {
        this.formBuilder = formBuilder;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.shopService = shopService;
        this.loaderService = loaderService;
        this.gamesProviderService = gamesProviderService;
        this.sanitizer = sanitizer;
        this.pointService = pointService;
        this.walletService = walletService;
        this.languageService = languageService;
        this.posSettingsService = posSettingsService;
        this.contentDisplayingService = contentDisplayingService;
        this.countryService = countryService;
        this.statesService = statesService;
        this.currencyService = currencyService;
        this.popupComponent = popupComponent;
        this.senetURL = null;
        this.entriesVal = 0;
        this.redeemedVal = 0;
        this.gd_mobile_id = '';
        this.gd_password = '';
        this.userPin = '';
        this.game_account = '';
        this.game_password = '';
        this.GAME_VENDOR_LINK = GAME_VENDOR_LINK;
        this.riverView = false;
        this.goldenDragonView = false;
        this.accountPasswordView = false;
        this.showWheel = false;
        this.gamePopupInfo = {
            show: false,
            availableBalance: 0,
            gameVendor: '',
            gameVendorName: '',
            partnerName: ''
        };
        this.popupShop = { show: false };
        this.providers = [];
        this.buyCryptoList = [];
        this.sellGiftCardsList = [];
        this.sellGiftCardsListSearch = [];
        this.RouterPaths = RouterPaths;
        this.siteInfo = {
            title: '',
            description: '',
            favicon: {
                72: '',
                120: '',
                180: ''
            }
        };
        this.showBuyCrypto = false;
        this.popupDebitCardInfo = { show: false };
    }
    ShopComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(function (val) {
            if (val) {
                setTimeout(function () {
                    _this.getPageInfo();
                });
            }
        });
        this.sellGiftCardsForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });
        this.searchCatalogForm = this.formBuilder.group({
            search: [null, Validators.required],
        });
        this.setPointInfo();
        this.listenSenetFrameClosing();
    };
    ShopComponent.prototype.ngOnDestroy = function () {
        this.languageChangeSubscription.unsubscribe();
    };
    ShopComponent.prototype.setPointInfo = function () {
        var _this = this;
        if (this.pointService.getPointInfo()) {
            this.getPageInfo();
        }
        else {
            var riverPayAccounts_1 = this.pointService.getPointAccounts();
            if (riverPayAccounts_1.length > 1) {
                var options = {};
                riverPayAccounts_1.map(function (o) {
                    options[o.point_id] = "<div class=\"point-name\">" + o.point_name + "</div>";
                });
                this.popupComponent.showPopupVariants({ accounts: options }, function (status) {
                    if (status) {
                        var pointInfo = riverPayAccounts_1.find(function (account) { return account.point_id == status; });
                        _this.pointService.savePointInfo(pointInfo);
                        _this.getPageInfo();
                    }
                });
            }
            else {
                if (riverPayAccounts_1.length) {
                    this.pointService.savePointInfo(riverPayAccounts_1[0]);
                    this.getPageInfo();
                }
                else {
                    this.popupComponent.showPopupError();
                }
            }
        }
    };
    ShopComponent.prototype.getPageInfo = function (game_short_name, only_balance, gameSelect) {
        var _this = this;
        if (game_short_name === void 0) { game_short_name = null; }
        if (only_balance === void 0) { only_balance = false; }
        if (gameSelect === void 0) { gameSelect = false; }
        this.shopService.getPageInfo(game_short_name, only_balance).subscribe(function (data) {
            _this.setPageInfo(data, game_short_name, only_balance, gameSelect);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    ShopComponent.prototype.setPageInfo = function (data, game_short_name, only_balance, gameSelect) {
        try {
            if (gameSelect) {
                this.setGamesProviders(data.games, gameSelect);
                this.checkPageView();
                this.scrollToPlayBtn();
                return;
            }
            this.showWheel = data.wheelShow;
            this.spinnerData = data.wheel;
            this.currencyService.setCurrency(data.currency);
            if (!game_short_name) {
                this.entriesVal = data.entries;
                this.redeemedVal = data.spend;
                this.posSettingsService.setSupportVisible(data.support);
                this.gamePopupInfo.availableBalance = this.entriesVal;
            }
            if (only_balance) {
                return;
            }
            else {
                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.showBuyCrypto = this.posSettingsService.getIfPaymentsAvailable();
                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                    this.checkPageView();
                }
                if (game_short_name) {
                    return;
                }
                this.buyCryptoList = [];
                if (data.buyCrypto && data.buyCrypto.length) {
                    this.buyCryptoList = data.buyCrypto;
                }
                this.sellGiftCardsList = [];
                if (data.sellGifts && data.sellGifts.length) {
                    this.sellGiftCardsList = data.sellGifts;
                    this.sellGiftCardsListSearch = this.sellGiftCardsList;
                }
                this.buyCryptoDescription = data.buyCryptoDescription;
                this.sellCryptoDescription = data.sellCryptoDescription;
                this.sellGiftsDescription = data.sellGiftsDescription;
                this.sellCryptoPercent = data.sellCryptoPercent;
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ShopComponent.prototype.checkPageView = function () {
        var _this = this;
        var gameShortName = this.selectedProvider.short_name;
        if (gameShortName === GAME_VENDORS_RIVER) {
            this.riverView = true;
            this.goldenDragonView = false;
            this.accountPasswordView = false;
        }
        else if (gameShortName === GAME_VENDORS_GOLDENDRAGON) {
            this.goldenDragonView = true;
            this.riverView = false;
            this.accountPasswordView = false;
        }
        else if (gameShortName === GAME_VENDORS_FIREKIRIN ||
            gameShortName === GAME_VENDORS_PUMA ||
            gameShortName === GAME_VENDORS_JUWA ||
            gameShortName === GAME_VENDORS_VBLINK ||
            gameShortName === GAME_VENDORS_ULTRAPANDA) {
            this.accountPasswordView = true;
            this.goldenDragonView = false;
            this.riverView = false;
        }
        else {
            this.accountPasswordView = false;
            this.riverView = false;
            this.goldenDragonView = false;
        }
        setTimeout(function () {
            _this.activatedRoute.queryParams.subscribe(function (params) {
                if (params.scrollToGames) {
                    var offset = document.querySelector("#gamesBtns").getBoundingClientRect().bottom - document.querySelector(".login__content").getBoundingClientRect().bottom;
                    document.querySelector('.login__content').scrollTo({
                        top: offset + 10,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        }, 0);
    };
    ShopComponent.prototype.setGamesProviders = function (providers, gameSelect) {
        if (gameSelect === void 0) { gameSelect = false; }
        if (!gameSelect) {
            this.providers = providers;
            this.providers.sort(function (a, b) {
                if (a.name < b.name)
                    return -1;
                if (a.name > b.name)
                    return 1;
                return 0;
            });
        }
        this.selectedProvider = providers.find(function (provider) { return provider.default; });
        if (!this.selectedProvider && providers.length) {
            this.selectedProvider = providers[0];
        }
        //this.limitedSweepsForm.controls.method.setValue(this.selectedProvider);
        this.selectedProviderId = this.selectedProvider.id;
        this.resetGamesInfo();
        if (this.selectedProvider.short_name === GAME_VENDORS_GOLDENDRAGON) {
            this.gd_mobile_id = this.selectedProvider.mobile_id;
            this.gd_password = this.selectedProvider.mobile_password;
            this.gamePopupInfo.gd_mobile_id = this.gd_mobile_id;
            this.gamePopupInfo.gd_password = this.gd_password;
        }
        else if (this.selectedProvider.short_name === GAME_VENDORS_RIVER) {
            this.userPin = this.selectedProvider.pin;
            this.gamePopupInfo.userPin = this.userPin;
        }
        else if (this.selectedProvider.short_name === GAME_VENDORS_FIREKIRIN ||
            this.selectedProvider.short_name === GAME_VENDORS_PUMA ||
            this.selectedProvider.short_name === GAME_VENDORS_JUWA ||
            this.selectedProvider.short_name === GAME_VENDORS_VBLINK ||
            this.selectedProvider.short_name === GAME_VENDORS_ULTRAPANDA) {
            this.game_account = this.selectedProvider.account;
            this.gamePopupInfo.game_account = this.game_account;
            this.game_password = this.selectedProvider.password;
            this.gamePopupInfo.game_password = this.game_password;
        }
    };
    ShopComponent.prototype.onProviderChange = function (game) {
        this.getPageInfo(game.short_name);
    };
    ShopComponent.prototype.onShowGamePopup = function () {
        document.body.classList.add('hide-menu');
        this.gamePopupInfo.show = true;
        this.gamePopupInfo.gameProviderId = this.selectedProvider.id;
        this.gamePopupInfo.gameVendor = this.selectedProvider.short_name;
        this.gamePopupInfo.gameVendorName = this.selectedProvider.name;
        this.gamePopupInfo.availableBalance = this.entriesVal;
        this.gamePopupInfo.partnerName = 'SENET';
    };
    ShopComponent.prototype.onGameSweep = function () {
        var game = this.selectedProvider.short_name;
        if (game == GAME_VENDORS_SENET) {
            this.getSenetSession();
        }
        else {
            this.copyToBuffer();
            this.goToSweep();
        }
        this.onCloseGamePopup();
    };
    ShopComponent.prototype.copyToBuffer = function (needMessage) {
        var tempInput = document.createElement('input');
        tempInput.value = this.riverView ? this.userPin : this.accountPasswordView ? this.game_account : this.gd_mobile_id;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        if (needMessage) {
            var message = this.languageService.getString('messagesData', 'codeCopied');
            this.popupComponent.showPopupSuccess({ text: message });
        }
    };
    ShopComponent.prototype.getSenetSession = function () {
        var _this = this;
        this.gamesProviderService.getSenetSession().subscribe(function (data) {
            _this.setSenetSession(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            // this.loaderService.hide();
        });
    };
    ShopComponent.prototype.setSenetSession = function (data) {
        try {
            if (data.status) {
                var url = data.sessionLink;
                this.openSentFrame(url);
            }
            else {
                this.loaderService.hide();
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ShopComponent.prototype.openSentFrame = function (url) {
        this.senetURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        var iframeWrapper = document.querySelector('.senet-iframe');
        var iframe = document.querySelector('.senet-iframe iframe');
        iframeWrapper.classList.add('is-active');
        iframe.onload = this.onSenetFrameLoaded.bind(this);
        iframe.src = url;
        // (iframe as HTMLIFrameElement).src = this.senetURL.changingThisBreaksApplicationSecurity;
        // (document.querySelector('app-root') as HTMLElement).classList.add('hide-menu');
        // (document.querySelector('.page-wrapper__decor-bottom') as HTMLElement).classList.add('is-senet-open');
    };
    ShopComponent.prototype.onCloseGamePopup = function () {
        document.body.classList.remove('hide-menu');
        this.gamePopupInfo.show = false;
    };
    ShopComponent.prototype.onUpdateBalance = function () {
        this.getPageInfo(null, true);
    };
    ShopComponent.prototype.goToSweep = function () {
        var gameShortName = this.selectedProvider.short_name;
        var link;
        switch (gameShortName) {
            case GAME_VENDORS_RIVER:
                link = this.GAME_VENDOR_LINK.river;
                break;
            case GAME_VENDORS_FIREKIRIN:
                link = this.GAME_VENDOR_LINK.firekirin;
                break;
            case GAME_VENDORS_PUMA:
                link = this.GAME_VENDOR_LINK.puma;
                break;
            case GAME_VENDORS_JUWA:
                link = this.GAME_VENDOR_LINK.juwa;
                break;
            case GAME_VENDORS_VBLINK:
                link = this.GAME_VENDOR_LINK.vblink;
                break;
            case GAME_VENDORS_ULTRAPANDA:
                link = this.GAME_VENDOR_LINK.ultrapanda;
                break;
            default:
                link = this.GAME_VENDOR_LINK.goldenDragon;
                break;
        }
        // const link = this.riverView ? this.GAME_VENDOR_LINK.river : this.accountPasswordView ? this.GAME_VENDOR_LINK.firekirin : this.GAME_VENDOR_LINK.goldenDragon;
        setTimeout(function () {
            var win = window.open(link, '_blank');
            win.focus();
        }, 250);
    };
    ShopComponent.prototype.resetGamesInfo = function () {
        this.gd_mobile_id = '';
        this.gd_password = '';
        this.game_account = '';
        this.game_password = '';
        this.userPin = '';
        this.gamePopupInfo.gd_mobile_id = '';
        this.gamePopupInfo.gd_password = '';
        this.gamePopupInfo.game_account = '';
        this.gamePopupInfo.game_password = '';
        this.gamePopupInfo.userPin = '';
    };
    ShopComponent.prototype.onGiftCardSearch = function ($event) {
        var val = $event.target.value.toLowerCase();
        if (val) {
            this.sellGiftCardsListSearch = this.sellGiftCardsList.filter(function (item) {
                return item.name.toLowerCase().indexOf(val) > -1;
            });
        }
        else {
            this.sellGiftCardsListSearch = this.sellGiftCardsList;
        }
    };
    ShopComponent.prototype.goToCheckout = function (part) {
        this.router.navigate([RouterPaths.checkout], { queryParams: { part: part } });
    };
    ShopComponent.prototype.onPopupShopClose = function () {
        document.body.classList.remove('hide-menu');
        this.popupShop = {
            show: false,
            title: '',
            type: null
        };
    };
    ShopComponent.prototype.onActivateBuy = function () {
        var message = this.languageService.getString('messagesData', 'askEnterActivationCode');
        this.onPopupShopOpen(message, SHOP_POPUP_TYPE.activateBuy);
    };
    ShopComponent.prototype.activateBuy = function (data) {
        try {
            if (data.status) {
                this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.activateBuy);
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ShopComponent.prototype.onSellGiftCards = function (id, name) {
        var title = this.languageService.getStringWithVariables([{ name: name }], 'sellGiftCardsPopupTitle');
        this.onPopupShopOpen(title, SHOP_POPUP_TYPE.sellGiftCard, id);
    };
    ShopComponent.prototype.onSellCrypto = function () {
        var _this = this;
        this.walletService.repeatFunction = this.onSellCrypto.bind(this);
        this.shopService.showSellCryptoPopup().subscribe(function (data) {
            _this.sellCrypto(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    ShopComponent.prototype.sellCrypto = function (data) {
        try {
            this.walletService.resetRepeatFunction();
            if (data.status) {
                var rate = data.price;
                var crypto_1 = data.bitcoinAddress;
                var qrCode = data.bitcoinQrcodeLink;
                var btc = data.bitcoinValue;
                this.onPopupShopOpen(data.description, SHOP_POPUP_TYPE.sellCrypto, null, rate, crypto_1, btc, qrCode);
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ShopComponent.prototype.onPopupShopOpen = function (title, type, id, rate, crypto, btc, qrCode) {
        if (id === void 0) { id = null; }
        if (rate === void 0) { rate = null; }
        if (crypto === void 0) { crypto = null; }
        if (btc === void 0) { btc = null; }
        if (qrCode === void 0) { qrCode = null; }
        document.body.classList.add('hide-menu');
        var availableBalance = rate && (btc !== null) ? rate * btc : this.entriesVal;
        this.popupShop = {
            show: true,
            title: title,
            type: type,
            id: id,
            rate: rate,
            crypto: crypto,
            btc: btc,
            qrCode: qrCode,
            availableBalance: availableBalance
        };
    };
    ShopComponent.prototype.onSendPopupForm = function ($event) {
        var _this = this;
        if (this.popupShop.type === SHOP_POPUP_TYPE.activateBuy) {
            var code = $event.code;
            this.shopService.onActivate(code).subscribe(function (data) {
                _this.setActivateBuyRequestResult(data, true);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else if (this.popupShop.type === SHOP_POPUP_TYPE.sellGiftCard) {
            var id = this.popupShop.id;
            var code = $event.code;
            var pin = $event.pin;
            var amount = $event.amount;
            this.shopService.onSellGiftCards(id, code, pin, amount).subscribe(function (data) {
                _this.setActivateBuyRequestResult(data, true);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else if (this.popupShop.type === SHOP_POPUP_TYPE.sellCrypto) {
            var amount = $event.amount;
            var password = $event.password;
            this.shopService.onSellCryptoRequest(amount, password).subscribe(function (data) {
                _this.setActivateBuyRequestResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    ShopComponent.prototype.setActivateBuyRequestResult = function (data, updateBalance) {
        if (updateBalance === void 0) { updateBalance = false; }
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({ text: data.message });
                if (updateBalance) {
                    this.getPageInfo(null, true);
                }
                this.onPopupShopClose();
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ShopComponent.prototype.onSenetFrameLoaded = function () {
        // if (event.target.src) {
        var title = this.selectedProvider.name;
        this.loaderService.hide();
        this.siteInfo.title = document.title;
        if (document.querySelector('meta[name="description"]')) {
            this.siteInfo.description = document.querySelector('meta[name="description"]').getAttribute("content");
            document.querySelector('meta[name="description"]').setAttribute("content", title);
        }
        document.title = title;
        this.changeThumbnailIcons(true);
        // }
    };
    ShopComponent.prototype.changeThumbnailIcons = function (isSenet) {
        if (isSenet === void 0) { isSenet = false; }
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', 72, isSenet);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', 180, isSenet);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', 120, isSenet);
    };
    ShopComponent.prototype.changeThumbnailIcon = function (selector, icon, size, isSenet) {
        if (isSenet === void 0) { isSenet = false; }
        var defaultIcon = document.querySelector(selector).getAttribute('href');
        var newIcon;
        if (isSenet) {
            this.siteInfo.favicon[size] = defaultIcon;
            newIcon = defaultIcon.split('/icons')[0] + ("/partners/senet/icons/" + icon);
        }
        else {
            newIcon = this.siteInfo.favicon[size];
        }
        document.querySelector(selector).setAttribute('href', newIcon);
    };
    ShopComponent.prototype.listenSenetFrameClosing = function () {
        var _this = this;
        window.addEventListener("message", function (e) {
            console.log(e);
            if (e['data'].close) {
                //this.senetURL = null;
                document.title = _this.siteInfo.title;
                if (document.querySelector('meta[name="description"]')) {
                    document.querySelector('meta[name="description"]').setAttribute("content", _this.siteInfo.description);
                }
                _this.changeThumbnailIcons(false);
                var iframeWrapper = document.querySelector('.senet-iframe');
                var iframe = document.querySelector('.senet-iframe iframe');
                iframeWrapper.classList.remove('is-active');
                iframe.onload = function () { };
                iframe.removeAttribute('src');
                _this.loaderService.hide();
            }
        }, false);
    };
    ShopComponent.prototype.onSpinResult = function (result) {
    };
    ShopComponent.prototype.showAdditionalContent = function () {
        return this.contentDisplayingService.showAdditionContent();
    };
    ShopComponent.prototype.onShowDebitCardPopup = function () {
        var _this = this;
        var isUSACountry = this.countryService.detectCountry() === COUNTRIES_SHORT.usa;
        if (isUSACountry) {
            this.statesService.getStates().subscribe(function (data) {
                try {
                    if (data.status) {
                        document.body.classList.add('hide-menu');
                        _this.popupDebitCardInfo = {
                            show: true,
                            type: POPUP_DEBIT_CARD_TYPE.simpleCard,
                            states: data.result
                        };
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: data.message });
                    }
                }
                catch (e) {
                    _this.loaderService.hideAndShowTryAgain();
                }
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            document.body.classList.add('hide-menu');
            this.popupDebitCardInfo = {
                show: true,
                type: POPUP_DEBIT_CARD_TYPE.simpleCard
            };
        }
    };
    ShopComponent.prototype.closeDebitCardPopup = function (needUpdate) {
        if (needUpdate === void 0) { needUpdate = false; }
        document.body.classList.remove('hide-menu');
        this.popupDebitCardInfo = {
            show: false,
        };
    };
    ShopComponent.prototype.onChangeSelectedProvider = function (game) {
        this.selectedProviderId = game.id;
        this.getPageInfo(game.short_name, false, true);
    };
    ShopComponent.prototype.formatCurrency = function (amount) {
        var amountFormatted = amount ? amount : 0;
        return this.currencyService.formatCurrency(amountFormatted);
    };
    ShopComponent.prototype.scrollToPlayBtn = function () {
        var element = document.getElementById('playBtn');
        element.classList.add('is-notouched');
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    ShopComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShopComponent_Factory() { return new ShopComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.ShopService), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.GamesProviderService), i0.ɵɵinject(i6.DomSanitizer), i0.ɵɵinject(i7.PointService), i0.ɵɵinject(i8.WalletService), i0.ɵɵinject(i9.LanguageService), i0.ɵɵinject(i10.PosSettingsService), i0.ɵɵinject(i11.ContentDisplayingService), i0.ɵɵinject(i12.CountryService), i0.ɵɵinject(i13.StatesService), i0.ɵɵinject(i14.CurrencyService), i0.ɵɵinject(i15.PopupComponent)); }, token: ShopComponent, providedIn: "root" });
    return ShopComponent;
}());
export { ShopComponent };
