import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { RouterPaths } from "../../config/router-paths.model";
import { PROJECT_MESSAGES } from "../../config/project";
import { POPUP_DEBIT_CARD_TYPE } from "../../shared/popup-debit-card/popup-debit-card.config";
import { CHECKOUT_CHARGE_PAYMENT_TYPE } from "../../shared/checkout-charge-popup/checkout-charge-popup.config";
import * as i0 from "@angular/core";
import * as i1 from "../../modules/currency/currency.service";
import * as i2 from "@angular/forms";
import * as i3 from "../../service/checkout.service";
import * as i4 from "../../service/loader.service";
import * as i5 from "../../service/scrollTo.service";
import * as i6 from "@angular/router";
import * as i7 from "../../service/pos-settings.service";
import * as i8 from "../../service/cards.service";
import * as i9 from "../../service/wallet.service";
import * as i10 from "../../modules/language/language.service";
import * as i11 from "../../service/content-displaying.service";
import * as i12 from "../../shared/popup/popup.component";
var pageSteps = {
    initial: 1,
    typeSelection: 2,
    settings: 3,
    terms: 4,
    generation: 5,
    spinner: 6
};
var CheckoutComponent = /** @class */ (function () {
    function CheckoutComponent(currencyService, formBuilder, checkoutService, loaderService, scrollToService, activatedRoute, posSettingsService, cardsService, walletService, languageService, contentDisplayingService, router, popupComponent) {
        this.currencyService = currencyService;
        this.formBuilder = formBuilder;
        this.checkoutService = checkoutService;
        this.loaderService = loaderService;
        this.scrollToService = scrollToService;
        this.activatedRoute = activatedRoute;
        this.posSettingsService = posSettingsService;
        this.cardsService = cardsService;
        this.walletService = walletService;
        this.languageService = languageService;
        this.contentDisplayingService = contentDisplayingService;
        this.router = router;
        this.popupComponent = popupComponent;
        this.entriesVal = 0;
        this.redeemedVal = 0;
        this.pageSteps = pageSteps;
        this.step = 1;
        this.unitsCost = 0;
        this.aiUnits = 0;
        this.aiUnitCost = 0;
        this.isUnfinished = false;
        this.showMint = false;
        this.mintAmount = 0;
        this.brushes = [];
        this.genBrushes = [];
        this.mintBrushesPage = 1;
        this.mintBrushes = [];
        this.mintBrushesSelected = [];
        this.generateDefaultImage = "";
        this.isTermsExpandedShown = false;
        this.RouterPaths = RouterPaths;
        this.showCashApp = false;
        this.cards = [];
        this.games = [];
        this.popupDebitCardInfo = { show: false };
        this.showNFTPopup = false;
        this.moreThenAvailablePayout = false;
        this.iframePopup = {
            show: false,
            url: null
        };
        this.verifyPopup = {
            show: false,
            id: null
        };
        this.states = [];
        this.viewIsCashAppHidden = false;
        this.viewIsCardsHidden = false;
        this.showLibraryBigImagePopup = false;
        this.generationIsFinished = false;
    }
    CheckoutComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(function (val) {
            if (val) {
                setTimeout(function () {
                    _this.getPageInfo(false, false, true);
                });
            }
        });
        document.body.classList.remove('hide-menu');
        this.checkOutForm = this.formBuilder.group({
            name: [null, Validators.required],
            generation: ['instant', Validators.required],
            terms: [false, Validators.required],
        });
        this.getPageInfo();
    };
    CheckoutComponent.prototype.ngOnDestroy = function () {
        this.languageChangeSubscription.unsubscribe();
    };
    CheckoutComponent.prototype.getPageInfo = function (onlyBalance, onlyCards, language) {
        var _this = this;
        if (onlyBalance === void 0) { onlyBalance = false; }
        if (onlyCards === void 0) { onlyCards = false; }
        if (language === void 0) { language = false; }
        this.checkoutService.getPageInfo(onlyBalance).subscribe(function (data) {
            _this.setPageInfo(data, onlyBalance, onlyCards, language);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.setPageInfo = function (data, only_balance, onlyCards, language) {
        try {
            if (onlyCards) {
                this.cards = [];
                if (data.cards && data.cards.length) {
                    this.cards = data.cards;
                    this.activeCard = this.cards[this.cards.length - 1];
                    this.activeMethod = this.cards[this.cards.length - 1];
                    this.scrollToLastMethod();
                }
                return;
            }
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);
            if (only_balance) {
                return;
            }
            else {
                if (!language) {
                    this.generationIsFinished = false;
                }
                this.games = data.games;
                this.defaultKeyWords = data.aiDefaultText;
                this.terms = data.terms;
                this.brushes = [];
                if (data.aiStyles) {
                    this.brushes = data.aiStyles;
                    if (!language) {
                        this.selectedBrush = data.aiStyles[0].id;
                    }
                }
                if (language) {
                    return;
                }
                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.viewIsCashAppHidden = !this.posSettingsService.isCashAppVisible();
                this.viewIsCardsHidden = !this.posSettingsService.isCardsVisible();
                this.cards = [];
                if (data.cards && data.cards.length) {
                    this.cards = data.cards;
                    this.activeCard = this.cards[this.cards.length - 1];
                    this.activeMethod = this.cards[this.cards.length - 1];
                    this.scrollToLastMethod();
                }
                document.getElementById('keyWords').innerText = '';
                document.getElementById('keyWords').value = '';
                this.checkOutForm.controls.terms.setValue(false);
                this.checkOutForm.controls.generation.setValue('instant');
                this.cashappInfo = data.cashappInfo;
                if (this.posSettingsService.isCashAppVisible() && !this.posSettingsService.isCardsVisible()) {
                    this.showCashApp = true;
                }
                if (!this.posSettingsService.getIfPaymentsAvailable()) {
                    this.router.navigate([RouterPaths.shop]);
                    return;
                }
                this.unitsCost = this.isUnfinished ? data.aiAmount : 0;
                this.aiUnits = this.isUnfinished ? data.aiUnits : 0;
                this.aiUnitCost = data.aiPrice;
                this.isUnfinished = data.aiImagesStatus;
                this.showMint = data.nftImagesStatus;
                if (this.showMint) {
                    this.mintBrushesPage = 1;
                    this.getMintInfo(this.mintBrushesPage);
                }
                this.states = data.states;
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    CheckoutComponent.prototype.onShowCashApp = function (val) {
        this.showCashApp = val;
    };
    CheckoutComponent.prototype.scrollToLastMethod = function () {
        this.scrollToService.scrollToEnd('.saved__list');
    };
    CheckoutComponent.prototype.closeDebitCardPopup = function (needUpdate) {
        if (needUpdate === void 0) { needUpdate = false; }
        document.body.classList.remove('hide-menu');
        this.popupDebitCardInfo = {
            show: false,
        };
        if (needUpdate) {
            this.getPageInfo(false, true);
        }
    };
    CheckoutComponent.prototype.showDebitCardPopup = function () {
        document.body.classList.add('hide-menu');
        this.popupDebitCardInfo = {
            show: true,
            type: POPUP_DEBIT_CARD_TYPE.simpleCard
        };
    };
    CheckoutComponent.prototype.onSubmit = function () {
        if (!this.showCashApp) {
            if (!this.cards.length) {
                this.popupComponent.showPopupError({ text: this.languageService.getString('messagesData', 'askToEnterPaymentMethod') });
            }
            else {
                if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
                    this.showNoVerifyErrorMessage();
                }
                else {
                    this.showConfirmMessage();
                }
            }
        }
        else {
            this.showConfirmMessage();
        }
    };
    CheckoutComponent.prototype.showNoVerifyErrorMessage = function () {
        if (this.activeMethod && this.activeMethod.last_four) {
            var message = this.activeMethod.description ?
                this.languageService.getStringWithVariables([{ description: this.activeMethod.description }, { lastFour: this.activeMethod.last_four }], 'messagesData', 'askVerifyCardEndingIn') + (" " + this.activeMethod.last_four) :
                this.languageService.getString('messagesData', 'askVerifyCardEndingIn') + " " + this.activeMethod.last_four + ".";
            // `Please verify ${this.activeMethod.description} - ${this.activeMethod.last_four} first.` :
            // `Please verify card ending in ${this.activeMethod.last_four}.`;
            this.popupComponent.showPopupError({ text: message });
        }
        else {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.unknownError });
        }
        return;
    };
    CheckoutComponent.prototype.showConfirmMessage = function () {
        this.walletService.repeatFunction = this.showConfirmMessage.bind(this);
        var cardId;
        if (!this.showCashApp) {
            cardId = this.activeMethod.id;
        }
        var amount = this.unitsCost;
        var generation = this.checkOutForm.controls.generation.value;
        var text = document.getElementById('keyWords').value;
        var selectedImage = this.selectedBrush;
        if (amount && generation && text && selectedImage && ((cardId && !this.showCashApp) || (!cardId && this.showCashApp))) {
            this.onShowNFTPopup();
        }
        else {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.unknownError });
        }
    };
    CheckoutComponent.prototype.onShowNFTPopup = function () {
        var inputAmount = this.unitsCost;
        // if (!this.showCashApp) {
        //     const description = this.activeMethod.description;
        //     const number = this.activeMethod.last_four;
        //     data.cardInfo = {
        //         description,
        //         number
        //     }
        // }
        this.showNFTPopup = true;
        this.NFTPopupData = {
            data: {
                payment: this.showCashApp ? CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp : CHECKOUT_CHARGE_PAYMENT_TYPE.debitCard,
                inputAmount: inputAmount
            }
        };
        if (this.showCashApp) {
            this.NFTPopupData.data.cashappInfo = this.cashappInfo;
        }
        this.openNFTPopup({ showNFTPopup: true, data: this.NFTPopupData });
    };
    CheckoutComponent.prototype.openNFTPopup = function ($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
    };
    CheckoutComponent.prototype.onPopupNFTClose = function () {
        document.body.classList.remove('hide-menu');
        this.showNFTPopup = false;
    };
    CheckoutComponent.prototype.showIframePopup = function (url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        };
    };
    CheckoutComponent.prototype.hideIframePopup = function (result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        };
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            }
            else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({ text: result.message });
                    var generation = this.checkOutForm.controls.generation.value;
                    if (generation !== 'instant') {
                        this.showGeneration();
                    }
                    else {
                        this.goToStep(this.pageSteps.initial);
                        this.getPageInfo();
                    }
                }
                else {
                    this.popupComponent.showPopupError({ text: result.message, confirmButtonText: this.languageService.getString('ok') });
                }
            }
        }
    };
    CheckoutComponent.prototype.onDepositConfirm = function ($event) {
        var _this = this;
        var amount = this.unitsCost;
        var generation = this.checkOutForm.controls.generation.value;
        var text = document.getElementById('keyWords').value;
        var selectedImage = this.selectedBrush;
        if (this.showCashApp) {
            var token = $event.token;
            this.checkoutService.buyWithCashApp(selectedImage, amount, generation, text, token).subscribe(function (data) {
                _this.setDepositConfirmResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            var cardId = this.activeMethod.id;
            this.checkoutService.buyWithCard(selectedImage, amount, generation, text, cardId).subscribe(function (data) {
                _this.setDepositConfirmResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    CheckoutComponent.prototype.setDepositConfirmResult = function (data) {
        try {
            if (data.status === true) {
                this.onPopupNFTClose();
                if (data.threeDSecure && !this.showCashApp) {
                    if (data.threeDSecureUrl) {
                        this.showIframePopup(data.threeDSecureUrl);
                    }
                    else {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }
                else {
                    this.popupComponent.showPopupSuccess({ text: data.message });
                    var generation = this.checkOutForm.controls.generation.value;
                    if (generation !== 'instant') {
                        this.showGeneration();
                    }
                    else {
                        this.goToStep(this.pageSteps.initial);
                        this.getPageInfo();
                    }
                }
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    CheckoutComponent.prototype.onCloseVerifyPopup = function (e) {
        var _this = this;
        document.body.classList.remove('hide-menu');
        if (e) {
            this.cards.forEach(function (card) {
                if (card.id === _this.verifyPopup.id) {
                    card.verify = 1;
                }
            });
        }
        this.verifyPopup.show = false;
        this.verifyPopup.id = null;
    };
    CheckoutComponent.prototype.formatCurrency = function (amount) {
        return this.currencyService.formatCurrency(amount) ? this.currencyService.formatCurrency(amount) : this.currencyService.formatCurrency(0);
    };
    CheckoutComponent.prototype.onUpdateUnits = function (val) {
        if (val) {
            this.unitsCost = +this.unitsCost + val;
        }
        else {
            this.unitsCost = 0;
        }
        this.aiUnits = +this.unitsCost / +this.aiUnitCost;
    };
    CheckoutComponent.prototype.onChangeSelectedBrush = function (id) {
        this.selectedBrush = id;
    };
    CheckoutComponent.prototype.showCheckoutTerms = function () {
        document.body.classList.add('hide-menu');
        this.isTermsExpandedShown = true;
    };
    CheckoutComponent.prototype.closeTermsExpanded = function ($event) {
        document.body.classList.remove('hide-menu');
        this.isTermsExpandedShown = false;
    };
    CheckoutComponent.prototype.goBack = function () {
        this.goToStep(this.step - 1);
    };
    CheckoutComponent.prototype.goNext = function () {
        if (this.step === this.pageSteps.initial) {
            if (!+this.unitsCost) {
                this.popupComponent.showPopupError({ text: this.languageService.getString('pleaseSelectAmountOfDeposit') });
                return;
            }
        }
        if (this.step === this.pageSteps.settings) {
            if (!document.getElementById('keyWords').value) {
                this.popupComponent.showPopupError({ text: this.languageService.getString('pleasEnterKeywordsOrClickButton') });
                return;
            }
            if (!this.viewIsCashAppHidden && this.cashappInfo) {
                this.NFTPopupData = {
                    data: {
                        payment: CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp,
                        inputAmount: this.unitsCost,
                        cashappInfo: this.cashappInfo
                    }
                };
            }
        }
        this.goToStep(this.step + 1);
    };
    CheckoutComponent.prototype.showMore = function () {
        var page = this.mintBrushesPage + 1;
        this.getMintInfo(page);
    };
    CheckoutComponent.prototype.getMintInfo = function (page) {
        var _this = this;
        this.checkoutService.getMintInfo(page).subscribe(function (data) {
            var _a;
            try {
                if (data.status) {
                    _this.mintAmount = +data.nfts;
                    _this.mintBrushes = (_a = _this.mintBrushes).concat.apply(_a, tslib_1.__spread(data.images));
                    _this.mintBrushesTotal = data.imagesTotal;
                    _this.mintBrushesPage = page;
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.onMint = function () {
        var _this = this;
        var images = Object.keys(this.mintBrushesSelected).map(function (key) { return _this.mintBrushesSelected[key].uuid; });
        this.checkoutService.onMintImages(images).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.popupComponent.showPopupSuccess({ text: data.message });
                    _this.spinnerData = data.wheel;
                    if (data.bonusWheel) {
                        setTimeout(function () {
                            _this.goToStep(_this.pageSteps.spinner);
                        }, 500);
                    }
                    else {
                        _this.mintBrushesPage = 1;
                        _this.mintAmount = 0;
                        _this.mintBrushes = [];
                        _this.mintBrushesSelected = [];
                        _this.goToStep(_this.pageSteps.initial);
                        _this.getPageInfo();
                    }
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.onSpinResult = function (result) {
        var _this = this;
        if (result) {
            setTimeout(function () {
                _this.goToStep(_this.pageSteps.initial);
                _this.getPageInfo();
            }, 5000);
        }
    };
    CheckoutComponent.prototype.onMintBrushChange = function (e, item) {
        if (!this.mintBrushesSelected.length && this.mintAmount) {
            this.mintBrushesSelected.push(item);
        }
        else {
            if (this.mintAmount && ((this.mintBrushesSelected.length !== this.mintAmount) || !e.target.checked)) {
                var isElementExist_1 = false;
                this.mintBrushesSelected.forEach(function (el) {
                    if (item.uuid === el.uuid) {
                        isElementExist_1 = true;
                    }
                });
                if (isElementExist_1) {
                    this.mintBrushesSelected = this.mintBrushesSelected.filter(function (brush) { return brush.uuid !== item.uuid; });
                }
                else {
                    this.mintBrushesSelected.push(item);
                }
            }
            else {
                e.target.checked = false;
                this.popupComponent.showPopupError({ text: this.languageService.getString('youCanChooseOnly') + " " + this.mintAmount + " " + (this.mintAmount > 1 ? this.languageService.getString('images') : this.languageService.getString('image')) });
            }
        }
    };
    CheckoutComponent.prototype.showUnfinishedWork = function () {
        this.showGeneration();
    };
    CheckoutComponent.prototype.showGeneration = function () {
        var _this = this;
        this.goToStep(this.pageSteps.generation);
        this.checkoutService.getGenerateInfo().subscribe(function (data) {
            _this.setGenerateSteepInfo(data);
        }, function () {
            _this.goToStep(_this.pageSteps.initial);
            _this.getPageInfo();
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.setGenerateSteepInfo = function (data) {
        try {
            if (data.status) {
                this.generateDefaultImage = data.imageDefault;
                this.generateAllImagesAmount = data.total;
                this.generatedImagesAmount = data.current;
                this.genBrushes = data.images;
                document.getElementById('keyWords').innerText = data.keywords;
                document.getElementById('keyWords').value = data.keywords;
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
                this.goToStep(this.pageSteps.initial);
                this.getPageInfo();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
            this.goToStep(this.pageSteps.initial);
            this.getPageInfo();
        }
    };
    CheckoutComponent.prototype.goToStep = function (step) {
        // document.querySelector('.login__content').scrollTop = 0;
        this.step = step;
        document.querySelector('.login__content').scrollTo({ top: 0, behavior: 'smooth' });
    };
    CheckoutComponent.prototype.generateOne = function () {
        var _this = this;
        var text = document.getElementById('keyWords').value;
        this.checkoutService.generateOne(text).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.generateDefaultImage = data.link;
                    _this.generatedImagesAmount = +_this.generatedImagesAmount + 1;
                    _this.genBrushes = data.images;
                    if (_this.genBrushes.length === _this.generateAllImagesAmount) {
                        //this.popupComponent.showPopupSuccess({text: data.message});
                        _this.generationIsFinished = true;
                    }
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.onBuyNew = function () {
        this.generationIsFinished = false;
        this.goToStep(this.pageSteps.initial);
        this.getPageInfo();
    };
    CheckoutComponent.prototype.generateAll = function () {
        var _this = this;
        var text = document.getElementById('keyWords').value;
        this.checkoutService.generateAll(text).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.popupComponent.showPopupSuccess({ text: data.message });
                    _this.goToStep(_this.pageSteps.initial);
                    _this.getPageInfo();
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.setDefaultKeyWords = function () {
        var _this = this;
        this.checkoutService.generateText().subscribe(function (data) {
            try {
                if (data.status) {
                    document.getElementById('keyWords').innerText = data.result;
                    document.getElementById('keyWords').value = data.result;
                }
                else {
                    //this.popupComponent.showPopupError({text: data.message});
                    document.getElementById('keyWords').innerText = _this.defaultKeyWords;
                    document.getElementById('keyWords').value = _this.defaultKeyWords;
                }
            }
            catch (e) {
                document.getElementById('keyWords').innerText = _this.defaultKeyWords;
                document.getElementById('keyWords').value = _this.defaultKeyWords;
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
            document.getElementById('keyWords').innerText = _this.defaultKeyWords;
            document.getElementById('keyWords').value = _this.defaultKeyWords;
        }, function () {
            _this.loaderService.hide();
        });
    };
    CheckoutComponent.prototype.isGenerateAll = function () {
        //instant manual
        if (this.checkOutForm && this.checkOutForm.controls.generation) {
            return this.checkOutForm.controls.generation.value === 'instant';
        }
    };
    CheckoutComponent.prototype.resetToGenerateAll = function () {
        this.checkOutForm.controls.generation.setValue('instant');
    };
    CheckoutComponent.prototype.showVerifyPopup = function (item) {
        document.body.classList.add('hide-menu');
        this.verifyPopup.show = true;
        this.verifyPopup.id = item.id;
    };
    CheckoutComponent.prototype.onDeleteCard = function (card) {
        var _this = this;
        var lastFour = card.last_four;
        var cardId = card.id;
        var text = this.languageService.getString('messagesData', 'confirmDeletingCard') + ' ' + this.languageService.getString('messagesData', 'endingIn') + ' ' + lastFour + '?';
        this.popupComponent.showPopupConfirmCancel({ text: text }, function (status) {
            if (status) {
                _this.cardsService.removeBankAccount(cardId).subscribe(function (data) {
                    try {
                        if (data.status === true) {
                            _this.cards = _this.cards.filter(function (card) { return card.id !== cardId; });
                        }
                        else if (data.status === false) {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                        else {
                            _this.loaderService.hideAndShowTryAgain();
                        }
                    }
                    catch (e) {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                }, function (error) {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        });
    };
    CheckoutComponent.prototype.changeMethod = function (method) {
        if (method) {
            this.activeMethod = method;
        }
    };
    CheckoutComponent.prototype.showAdditionContent = function () {
        return this.contentDisplayingService.showAdditionContent();
    };
    CheckoutComponent.prototype.getLanguageString = function (val) {
        return this.languageService.getString(val);
    };
    CheckoutComponent.prototype.onShowLibraryBigImagePopup = function (slide) {
        // document.body.classList.add('hide-menu');
        // document.querySelector('.login__forms').classList.add('blur');
        this.showLibraryBigImagePopup = true;
        this.libraryBigImagePopupData = {
            data: slide
        };
    };
    CheckoutComponent.prototype.onPopupLibraryBigImageClose = function () {
        this.showLibraryBigImagePopup = false;
        // document.querySelector('.login__forms').classList.remove('blur');
    };
    CheckoutComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckoutComponent_Factory() { return new CheckoutComponent(i0.ɵɵinject(i1.CurrencyService), i0.ɵɵinject(i2.FormBuilder), i0.ɵɵinject(i3.CheckoutService), i0.ɵɵinject(i4.LoaderService), i0.ɵɵinject(i5.ScrollToService), i0.ɵɵinject(i6.ActivatedRoute), i0.ɵɵinject(i7.PosSettingsService), i0.ɵɵinject(i8.CardsService), i0.ɵɵinject(i9.WalletService), i0.ɵɵinject(i10.LanguageService), i0.ɵɵinject(i11.ContentDisplayingService), i0.ɵɵinject(i6.Router), i0.ɵɵinject(i12.PopupComponent)); }, token: CheckoutComponent, providedIn: "root" });
    return CheckoutComponent;
}());
export { CheckoutComponent };
