import {
    Component,
    OnInit, Injectable, ViewChild, ViewContainerRef
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {RouterPaths} from "../../config/router-paths.model";
import {ShopService} from "../../service/shop.service";
import {LoaderService} from "../../service/loader.service";
import {PopupRewardsGameInfoModel} from "./popup-rewards-game-form/popup-rewards-game-form.config";
import {
  GAME_VENDOR_LINK, GAME_VENDORS_FIREKIRIN,
  GAME_VENDORS_GOLDENDRAGON, GAME_VENDORS_JUWA, GAME_VENDORS_PUMA,
  GAME_VENDORS_RIVER,
  GAME_VENDORS_SENET, GAME_VENDORS_ULTRAPANDA, GAME_VENDORS_VBLINK
} from "../../config/gameVendors";
import {DomSanitizer} from "@angular/platform-browser";
import {GetSenetSessionResponseModel} from "../../models/providers/getSenetSessionResponse.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {SHOP_POPUP_TYPE} from "./shop.config";
import {WalletService} from "../../service/wallet.service";
import {PointService} from "../../service/point.service";
import {PosSettingsService} from "../../service/pos-settings.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {POPUP_DEBIT_CARD_TYPE} from "../../shared/popup-debit-card/popup-debit-card.config";
import {COUNTRIES_SHORT} from "../../config/countries.config";
import {CountryService} from "../../modules/country/country.service";
import {StatesService} from "../../service/states.service";

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss'],
})
@Injectable({providedIn: 'root'})
export class ShopComponent implements OnInit {
    sellGiftCardsForm: FormGroup;
    searchCatalogForm: FormGroup;
    senetURL = null;

    entriesVal = 0;
    redeemedVal = 0;

    gd_mobile_id = '';
    gd_password = '';
    userPin = '';
    game_account = '';
    game_password = '';

    GAME_VENDOR_LINK = GAME_VENDOR_LINK;

    riverView = false;
    goldenDragonView = false;
    accountPasswordView = false;

    showWheel = false;
    spinnerData;

    gamePopupInfo: PopupRewardsGameInfoModel = {
        show: false,
        availableBalance: 0,
        gameVendor: '',
        gameVendorName: '',
        partnerName: ''
    };

    popupShop: any = {show: false};

    providers = [];
    selectedProvider;
    selectedProviderId;

    buyCryptoList = [];
    sellGiftCardsList = [];
    sellGiftCardsListSearch = [];

    buyCryptoDescription: string;
    sellCryptoDescription: string;
    sellGiftsDescription: string;
    sellCryptoPercent: number;

    RouterPaths = RouterPaths;
    @ViewChild("popupGenerate", { read: ViewContainerRef, static: false }) popupGenerateContainer: ViewContainerRef;

    siteInfo = {
        title: '',
        description: '',
        favicon: {
            72: '',
            120: '',
            180: ''
        }
    };

    showBuyCrypto = false;
    popupDebitCardInfo: any = {show: false};
    languageChangeSubscription;
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private shopService: ShopService,
        private loaderService: LoaderService,
        private gamesProviderService: GamesProviderService,
        public sanitizer: DomSanitizer,
        private pointService: PointService,
        private walletService: WalletService,
        private languageService: LanguageService,
        private posSettingsService: PosSettingsService,
        private contentDisplayingService: ContentDisplayingService,
        private countryService: CountryService,
        private statesService: StatesService,
        private currencyService: CurrencyService,
        private popupComponent: PopupComponent) {
    }
    ngOnInit() {
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(val => {
            if (val) {
                setTimeout(() => {
                    this.getPageInfo();
                });
            }
        });

        this.sellGiftCardsForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });

        this.searchCatalogForm = this.formBuilder.group({
            search: [null, Validators.required],
        });

        this.setPointInfo();
        this.listenSenetFrameClosing();
    }

    ngOnDestroy() {
        this.languageChangeSubscription.unsubscribe();
    }

    setPointInfo() {
        if (this.pointService.getPointInfo()) {
            this.getPageInfo();
        } else {
            const riverPayAccounts = this.pointService.getPointAccounts();
            if (riverPayAccounts.length > 1) {
                var options = {};

                riverPayAccounts.map(function (o) {
                    options[o.point_id] = `<div class="point-name">${o.point_name}</div>`;
                });

                this.popupComponent.showPopupVariants(
                  {accounts: options}, (status) => {
                      if (status) {
                          const pointInfo = riverPayAccounts.find(account => account.point_id == status);
                          this.pointService.savePointInfo(pointInfo);
                          this.getPageInfo();
                      }
                  });
            } else {
                if (riverPayAccounts.length) {
                    this.pointService.savePointInfo(riverPayAccounts[0]);
                    this.getPageInfo();
                } else {
                    this.popupComponent.showPopupError();
                }
            }
        }
    }

    getPageInfo(game_short_name = null, only_balance = false, gameSelect = false) {
        this.shopService.getPageInfo(game_short_name, only_balance).subscribe(data => {
            this.setPageInfo(data, game_short_name, only_balance, gameSelect);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setPageInfo(data, game_short_name, only_balance, gameSelect) {
        try {
            if (gameSelect) {
              this.setGamesProviders(data.games, gameSelect);
              this.checkPageView();
              this.scrollToPlayBtn();
              return;
            }

            this.showWheel = data.wheelShow;
            this.spinnerData = data.wheel;
            this.currencyService.setCurrency(data.currency);
            if (!game_short_name) {
                this.entriesVal = data.entries;
                this.redeemedVal = data.spend;
                this.posSettingsService.setSupportVisible(data.support);

                this.gamePopupInfo.availableBalance = this.entriesVal;
            }

            if (only_balance) {
                return;
            } else {
                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.showBuyCrypto = this.posSettingsService.getIfPaymentsAvailable();

                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                    this.checkPageView();
                }
                if (game_short_name) {
                    return;
                }

                this.buyCryptoList = [];
                if (data.buyCrypto && data.buyCrypto.length) {
                    this.buyCryptoList = data.buyCrypto;
                }

                this.sellGiftCardsList = [];
                if (data.sellGifts && data.sellGifts.length) {
                    this.sellGiftCardsList = data.sellGifts;
                    this.sellGiftCardsListSearch = this.sellGiftCardsList;
                }

                this.buyCryptoDescription = data.buyCryptoDescription;
                this.sellCryptoDescription = data.sellCryptoDescription;
                this.sellGiftsDescription = data.sellGiftsDescription;
                this.sellCryptoPercent = data.sellCryptoPercent;
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkPageView() {
        const gameShortName = this.selectedProvider.short_name;
        if (gameShortName === GAME_VENDORS_RIVER) {
            this.riverView = true;
            this.goldenDragonView = false;
            this.accountPasswordView = false;
        } else if (gameShortName === GAME_VENDORS_GOLDENDRAGON) {
            this.goldenDragonView = true;
            this.riverView = false;
            this.accountPasswordView = false;
        } else if (gameShortName === GAME_VENDORS_FIREKIRIN ||
                   gameShortName === GAME_VENDORS_PUMA ||
                   gameShortName === GAME_VENDORS_JUWA ||
                   gameShortName === GAME_VENDORS_VBLINK ||
                   gameShortName === GAME_VENDORS_ULTRAPANDA) {
            this.accountPasswordView = true;
            this.goldenDragonView = false;
            this.riverView = false;
        }  else {
            this.accountPasswordView = false;
            this.riverView = false;
            this.goldenDragonView = false;
        }
        setTimeout(() => {
            this.activatedRoute.queryParams.subscribe(params => {
                if (params.scrollToGames) {
                    const offset = document.querySelector("#gamesBtns").getBoundingClientRect().bottom - document.querySelector(".login__content").getBoundingClientRect().bottom;
                    document.querySelector('.login__content').scrollTo({
                        top: offset + 10,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            })
        }, 0);
    }

    setGamesProviders(providers, gameSelect = false) {
        if (!gameSelect) {
          this.providers = providers;
          this.providers.sort((a,b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        }

        this.selectedProvider = providers.find(provider => provider.default);
        if (!this.selectedProvider && providers.length) {
            this.selectedProvider = providers[0]
        }
        //this.limitedSweepsForm.controls.method.setValue(this.selectedProvider);
        this.selectedProviderId = this.selectedProvider.id;
        
        this.resetGamesInfo();
        if (this.selectedProvider.short_name === GAME_VENDORS_GOLDENDRAGON) {
            this.gd_mobile_id = this.selectedProvider.mobile_id;
            this.gd_password = this.selectedProvider.mobile_password;
            this.gamePopupInfo.gd_mobile_id = this.gd_mobile_id;
            this.gamePopupInfo.gd_password = this.gd_password;
        } else if (this.selectedProvider.short_name === GAME_VENDORS_RIVER) {
            this.userPin = this.selectedProvider.pin;
            this.gamePopupInfo.userPin = this.userPin;
        } else if (this.selectedProvider.short_name === GAME_VENDORS_FIREKIRIN ||
                   this.selectedProvider.short_name === GAME_VENDORS_PUMA ||
                   this.selectedProvider.short_name === GAME_VENDORS_JUWA ||
                   this.selectedProvider.short_name === GAME_VENDORS_VBLINK ||
                   this.selectedProvider.short_name === GAME_VENDORS_ULTRAPANDA) {
            this.game_account = this.selectedProvider.account;
            this.gamePopupInfo.game_account = this.game_account;
            this.game_password = this.selectedProvider.password;
            this.gamePopupInfo.game_password = this.game_password;
        }
    }

    onProviderChange(game) {
        this.getPageInfo(game.short_name);
    }

    onShowGamePopup() {
        document.body.classList.add('hide-menu');
        this.gamePopupInfo.show = true;
        this.gamePopupInfo.gameProviderId = this.selectedProvider.id;
        this.gamePopupInfo.gameVendor = this.selectedProvider.short_name;
        this.gamePopupInfo.gameVendorName = this.selectedProvider.name;
        this.gamePopupInfo.availableBalance = this.entriesVal;
        this.gamePopupInfo.partnerName = 'SENET';
    }

    onGameSweep() {
        const game = this.selectedProvider.short_name;
        if(game == GAME_VENDORS_SENET){
            this.getSenetSession();
        } else {
            this.copyToBuffer();
            this.goToSweep();
        }
        this.onCloseGamePopup();
    }

    copyToBuffer(needMessage?) {
        let tempInput = document.createElement('input');
        tempInput.value = this.riverView ? this.userPin : this.accountPasswordView ? this.game_account : this.gd_mobile_id;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        if (needMessage) {
            const message = this.languageService.getString('messagesData', 'codeCopied');
            this.popupComponent.showPopupSuccess({text: message});
        }
    }

    getSenetSession() {
        this.gamesProviderService.getSenetSession().subscribe(data => {
            this.setSenetSession(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            // this.loaderService.hide();
        });
    }

    setSenetSession(data: GetSenetSessionResponseModel): void {
        try {
            if (data.status) {
                const url = data.sessionLink;
                this.openSentFrame(url);
            } else {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    openSentFrame(url: string): void {
        this.senetURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        const iframeWrapper = document.querySelector('.senet-iframe');
        const iframe = document.querySelector('.senet-iframe iframe');

        iframeWrapper.classList.add('is-active');
        (iframe as HTMLIFrameElement).onload = this.onSenetFrameLoaded.bind(this);
        (iframe as HTMLIFrameElement).src = url;
        // (iframe as HTMLIFrameElement).src = this.senetURL.changingThisBreaksApplicationSecurity;
        // (document.querySelector('app-root') as HTMLElement).classList.add('hide-menu');
        // (document.querySelector('.page-wrapper__decor-bottom') as HTMLElement).classList.add('is-senet-open');
    }

    onCloseGamePopup() {
        document.body.classList.remove('hide-menu');
        this.gamePopupInfo.show = false;
    }

    onUpdateBalance() {
        this.getPageInfo(null, true);
    }

    goToSweep() {
        const gameShortName = this.selectedProvider.short_name;
        let link;
        switch (gameShortName) {
            case GAME_VENDORS_RIVER:
                link = this.GAME_VENDOR_LINK.river;
                break;
            case GAME_VENDORS_FIREKIRIN:
                link = this.GAME_VENDOR_LINK.firekirin;
                break;
            case GAME_VENDORS_PUMA:
                link = this.GAME_VENDOR_LINK.puma;
                break;
            case GAME_VENDORS_JUWA:
                link = this.GAME_VENDOR_LINK.juwa;
                break;
            case GAME_VENDORS_VBLINK:
                link = this.GAME_VENDOR_LINK.vblink;
                break;
            case GAME_VENDORS_ULTRAPANDA:
                link = this.GAME_VENDOR_LINK.ultrapanda;
                break;
            default:
                link = this.GAME_VENDOR_LINK.goldenDragon;
                break;
        }
        // const link = this.riverView ? this.GAME_VENDOR_LINK.river : this.accountPasswordView ? this.GAME_VENDOR_LINK.firekirin : this.GAME_VENDOR_LINK.goldenDragon;
        setTimeout(() => {
            var win = window.open(link, '_blank');
            win.focus();
        }, 250);
    }

    resetGamesInfo() {
        this.gd_mobile_id = '';
        this.gd_password = '';
        this.game_account = '';
        this.game_password = '';
        this.userPin = '';
        this.gamePopupInfo.gd_mobile_id = '';
        this.gamePopupInfo.gd_password = '';
        this.gamePopupInfo.game_account = '';
        this.gamePopupInfo.game_password = '';
        this.gamePopupInfo.userPin = '';
    }

    onGiftCardSearch($event) {
        const val = $event.target.value.toLowerCase();
        if (val) {
            this.sellGiftCardsListSearch = this.sellGiftCardsList.filter(item => {
                return item.name.toLowerCase().indexOf(val) > -1;
            });
        } else {
            this.sellGiftCardsListSearch = this.sellGiftCardsList;
        }
    }

    goToCheckout(part) {
        this.router.navigate([RouterPaths.checkout], {queryParams: {part}});
    }

    onPopupShopClose() {
        document.body.classList.remove('hide-menu');
        this.popupShop = {
            show: false,
            title: '',
            type: null
        }
    }

    onActivateBuy() {
        const message = this.languageService.getString('messagesData', 'askEnterActivationCode');
        this.onPopupShopOpen(message, SHOP_POPUP_TYPE.activateBuy);
    }

    activateBuy(data): void {
        try {
            if (data.status) {
                this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.activateBuy);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSellGiftCards(id, name) {
        const title = this.languageService.getStringWithVariables([{name: name}], 'sellGiftCardsPopupTitle');
        this.onPopupShopOpen(title, SHOP_POPUP_TYPE.sellGiftCard, id);
    }

    onSellCrypto() {
        this.walletService.repeatFunction = this.onSellCrypto.bind(this);
        this.shopService.showSellCryptoPopup().subscribe(data => {
            this.sellCrypto(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    sellCrypto(data): void {
        try {
            this.walletService.resetRepeatFunction();
            if (data.status) {
                const rate = data.price;
                const crypto = data.bitcoinAddress;
                const qrCode = data.bitcoinQrcodeLink;
                const btc = data.bitcoinValue;
                this.onPopupShopOpen(data.description, SHOP_POPUP_TYPE.sellCrypto, null, rate, crypto, btc, qrCode);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onPopupShopOpen(title, type, id = null, rate = null, crypto = null, btc = null, qrCode = null) {
        document.body.classList.add('hide-menu');
        const availableBalance = rate && (btc !== null) ? rate * btc : this.entriesVal;
        this.popupShop = {
            show: true,
            title,
            type,
            id,
            rate,
            crypto,
            btc,
            qrCode,
            availableBalance
        }
    }

    onSendPopupForm($event) {
        if (this.popupShop.type === SHOP_POPUP_TYPE.activateBuy) {
            const code = $event.code;
            this.shopService.onActivate(code).subscribe(data => {
                this.setActivateBuyRequestResult(data, true);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.popupShop.type === SHOP_POPUP_TYPE.sellGiftCard) {
            const id = this.popupShop.id;
            const code = $event.code;
            const pin = $event.pin;
            const amount = $event.amount;
            this.shopService.onSellGiftCards(id, code, pin, amount).subscribe(data => {
                this.setActivateBuyRequestResult(data, true);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.popupShop.type === SHOP_POPUP_TYPE.sellCrypto) {
            const amount = $event.amount;
            const password = $event.password;
            this.shopService.onSellCryptoRequest(amount, password).subscribe(data => {
                this.setActivateBuyRequestResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    setActivateBuyRequestResult(data, updateBalance = false): void {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                if (updateBalance) {
                    this.getPageInfo(null, true);
                }
                this.onPopupShopClose();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSenetFrameLoaded() {
        // if (event.target.src) {
            const title = this.selectedProvider.name;
            this.loaderService.hide();
            this.siteInfo.title = document.title;
            if (document.querySelector('meta[name="description"]')) {
                this.siteInfo.description = document.querySelector('meta[name="description"]').getAttribute("content");
                document.querySelector('meta[name="description"]').setAttribute("content", title);
            }
            document.title = title;
            this.changeThumbnailIcons(true);
        // }
    }

    changeThumbnailIcons(isSenet = false) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', 72, isSenet);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', 180, isSenet);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', 120, isSenet);
    }

    changeThumbnailIcon(selector, icon, size: number, isSenet = false) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        let newIcon;
        if (isSenet) {
            this.siteInfo.favicon[size] = defaultIcon;
            newIcon = defaultIcon.split('/icons')[0] + `/partners/senet/icons/${icon}`;
        } else {
            newIcon = this.siteInfo.favicon[size];
        }
        document.querySelector(selector).setAttribute('href', newIcon);
    }

    listenSenetFrameClosing(): void {
        window.addEventListener("message", (e: Event) => {
            console.log(e);
            if (e['data'].close) {
                //this.senetURL = null;
                document.title = this.siteInfo.title;
                if (document.querySelector('meta[name="description"]')) {
                    document.querySelector('meta[name="description"]').setAttribute("content", this.siteInfo.description);
                }
                this.changeThumbnailIcons(false);

                const iframeWrapper = document.querySelector('.senet-iframe');
                const iframe = document.querySelector('.senet-iframe iframe');

                iframeWrapper.classList.remove('is-active');
                (iframe as HTMLIFrameElement).onload = () => {};
                (iframe as HTMLIFrameElement).removeAttribute('src');
                this.loaderService.hide();
            }
        }, false);
    }

    onSpinResult(result) {

    }

    showAdditionalContent() {
        return this.contentDisplayingService.showAdditionContent();
    }


    onShowDebitCardPopup() {
        const isUSACountry = this.countryService.detectCountry() === COUNTRIES_SHORT.usa;
        if (isUSACountry) {
            this.statesService.getStates().subscribe(data => {
                try {
                    if (data.status) {
                        document.body.classList.add('hide-menu');
                        this.popupDebitCardInfo = {
                            show: true,
                            type: POPUP_DEBIT_CARD_TYPE.simpleCard,
                            states: data.result
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            document.body.classList.add('hide-menu');
            this.popupDebitCardInfo = {
                show: true,
                type: POPUP_DEBIT_CARD_TYPE.simpleCard
            }
        }
    }

    closeDebitCardPopup(needUpdate = false) {
        document.body.classList.remove('hide-menu');
        this.popupDebitCardInfo = {
            show: false,
        };
    }

    onChangeSelectedProvider(game) {
        this.selectedProviderId = game.id;
        this.getPageInfo(game.short_name, false, true);
    }

    formatCurrency(amount) {
        const amountFormatted = amount ? amount : 0;
        return this.currencyService.formatCurrency(amountFormatted);
    }

    scrollToPlayBtn() {
        const element = document.getElementById('playBtn');
        element.classList.add('is-notouched');
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }
}

