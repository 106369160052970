/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/balance-heading/balance-heading.component.ngfactory";
import * as i3 from "../../shared/balance-heading/balance-heading.component";
import * as i4 from "../../modules/language/language.service";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/users/userBalance.service";
import * as i7 from "../../modules/currency/currency.service";
import * as i8 from "@angular/common";
import * as i9 from "./support.component";
import * as i10 from "@angular/router";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../service/support/support.service";
import * as i13 from "../../shared/popup/popup.component";
var styles_SupportComponent = [i0.styles];
var RenderType_SupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportComponent, data: {} });
export { RenderType_SupportComponent as RenderType_SupportComponent };
function View_SupportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "chat-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["frameborder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_SupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "login__forms"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "login__tab"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["style", "margin-bottom: 2rem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-balance-heading", [], null, [["window", "click"]], function (_v, en, $event) { var ad = true; if (("window:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BalanceHeadingComponent_0, i2.RenderType_BalanceHeadingComponent)), i1.ɵdid(4, 114688, null, 0, i3.BalanceHeadingComponent, [i4.LanguageService, i5.LoaderService, i6.UserBalanceService, i7.CurrencyService], { title: [0, "title"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupportComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Support"; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.frameUrl; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_SupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support", [], null, null, null, View_SupportComponent_0, RenderType_SupportComponent)), i1.ɵdid(1, 114688, null, 0, i9.SupportComponent, [i5.LoaderService, i10.ActivatedRoute, i10.Router, i11.DomSanitizer, i12.SupportService, i13.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupportComponentNgFactory = i1.ɵccf("app-support", i9.SupportComponent, View_SupportComponent_Host_0, {}, {}, []);
export { SupportComponentNgFactory as SupportComponentNgFactory };
