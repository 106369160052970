import {
    Component,
    OnInit,
    Injectable,
    ComponentFactoryResolver,
    ViewContainerRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../shared/popup/popup.component";
import {RouterPaths} from "../../config/router-paths.model";
import {LoaderService} from "../../service/loader.service";
import {
    SenetproTransactionsModel,
    TRANSACTIONS_LIST_TYPE
} from "../../shared/senetpro-transactions/senetpro-transactions.model";
import {AccountService} from "../../service/account.service";
import {WalletService} from "../../service/wallet.service";
import {ShopService} from "../../service/shop.service";
import {SHOP_POPUP_TYPE} from "../shop/shop.config";
import {PosSettingsService} from "../../service/pos-settings.service";
import {LanguageService} from "../../modules/language/language.service";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {CHANGE_USER_INFO_TYPE} from "../../shared/popup-change-user-info/popup-change-user-info.config";
import {POPUP_MORE_IMAGES_TYPE} from "../../shared/popup-more-images/popup-more-images.config";
import {ContentDisplayingService} from "../../service/content-displaying.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
@Injectable({providedIn: 'root'})
export class AccountComponent implements OnInit {
    contactsForm: FormGroup;
    TRANSACTIONS_LIST_TYPE = TRANSACTIONS_LIST_TYPE;
    CHANGE_USER_INFO_TYPE = CHANGE_USER_INFO_TYPE;
    pageForm: FormGroup;

    entriesVal = 0;
    redeemedVal = 0;

    phoneMask;

    bitcoinDescription: string;
    bitcoinAmount: number = 0;
    btcTransactionsLink: string;
    nftDescription: string;

    availableBalance = 0;
    providers = [];
    selectedProvider;

    RouterPaths = RouterPaths;

    moreThenAvailablePayout = false;

    showMoreTransactionsBtn = false;
    showMoreLibraryImages = false;
    showMoreLibraryImagesBtn = false;
    showMoreLibraryNFT = false;
    showMoreLibraryNFTBtn = false;
    transactionsList: SenetproTransactionsModel[] = [];
    imagesList = [];

    popupChannels: any = {show: false};
    popupTransactions = {show: false};
    popupConfirmInfo: any = {show: false, totalDebit: 0};
    popupShop: any = {show: false};
    popupChangeUserInfo: any = {show: false};
    popupImages = {
        show: false,
        type: null
    };

    showLibraryBigImagePopup = false;
    libraryBigImagePopupData;

    @ViewChild("popupGenerate", { read: ViewContainerRef, static: false }) popupGenerateContainer: ViewContainerRef;
    @ViewChild('iframeParent', {static: false}) iframeParent: ElementRef;

    privateKeyJson = null;
    nftData = {
        library: [],
        qrCode: '',
        wallet: '',
        nftDescription: ''
    };

    sellDescription;
    buyDescription;
    sendDescription;
    receiveDescription;

    notifyEmail = false;
    notifyText = false;
    isPhoneVerified = false;
    isEmailVerified = false;

    isCheckOutVisible = true;
    languageChangeSubscription;
    constructor(
        private formBuilder: FormBuilder,
        private accountService: AccountService,
        private loaderService: LoaderService,
        private shopService: ShopService,
        private walletService: WalletService,
        private phoneMaskService: PhoneMaskService,
        private languageService: LanguageService,
        private contentDisplayingService: ContentDisplayingService,
        private posSettingsService: PosSettingsService,
        private popupComponent: PopupComponent) {
    }

    ngOnInit() {
        this.languageChangeSubscription = this.languageService.languageIsUpdated.subscribe(val => {
            if (val) {
                setTimeout(() => {
                    this.getPageInfo();
                });
            }
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
        this.pageForm = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required],
        });
        this.contactsForm = this.formBuilder.group({
            firstName: [null],
            lastName: [null],
            address: [null],
            email: [null],
            phone: [null],
        });
        this.getPageInfo();
    }

    ngOnDestroy() {
        this.languageChangeSubscription.unsubscribe();
    }

    getPageInfo(page = 1, pageAi = 1, pageNft = 1, singleHistoryPage = false, singleAiHistoryPage = false, singleNftHistoryPage = false, only_balance = false) {
        this.walletService.repeatFunction = this.getPageInfo.bind(this);
        this.loaderService.show();
        this.accountService.getPageInfo(page, pageAi, pageNft, singleHistoryPage, singleAiHistoryPage, singleNftHistoryPage).subscribe(data => {
            this.setPageInfo(data, only_balance);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    setPageInfo(data, only_balance) {
        try {
            this.walletService.resetRepeatFunction();
            this.entriesVal = data.entries;
            this.redeemedVal = data.spend;
            this.posSettingsService.setSupportVisible(data.support);

            this.bitcoinAmount = data.btcAmount;

            if (only_balance) {
                return;
            } else {
                this.posSettingsService.setIfPaymentsAvailable(data.payments);
                this.isCheckOutVisible = this.posSettingsService.getIfPaymentsAvailable();

                this.bitcoinDescription = data.btcDescription;
                this.btcTransactionsLink = data.btcTransactionsLink;
                this.nftDescription = data.nftDescription;
                this.nftData.qrCode = data.nftAddressQrCode;
                this.nftData.wallet = data.nftAddress;
                this.nftData.nftDescription = data.nftSecondDescription;

                this.buyDescription = data.buyDescription;
                this.sellDescription = data.sellDescription;
                this.sendDescription = data.sendDescription;
                this.receiveDescription = data.receiveDescription;

                this.providers = [];
                if (data.games && data.games.length) {
                    this.setGamesProviders(data.games);
                }

                this.setTransactionsList(data.products, data.productsLimit, data.productsTotal);
                this.setImagesLibraryList(data.aiImages, data.aiLimit, data.aiTotal);
                this.setNftLibraryList(data.nftImages, data.nftLimit, data.nftTotal);

                this.setContacts(data);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setContacts(data) {
        if (data && data.settings) {
            const info = data.settings;
            const firstName = info.firstName ? info.firstName : null;
            const lastName = info.lastName ? info.lastName : null;
            const address = info.address ? info.address : null;
            const email = info.email ? info.email : null;
            const phone = info.phone ? info.phone : null;

            this.contactsForm.controls.firstName.setValue(firstName);
            this.contactsForm.controls.lastName.setValue(lastName);
            this.contactsForm.controls.address.setValue(address);
            this.contactsForm.controls.email.setValue(email);
            this.contactsForm.controls.firstName.setValue(firstName);
            this.contactsForm.controls.phone.setValue(phone);

            this.isPhoneVerified = !!info.phoneVerify;
            this.isEmailVerified = !!info.emailVerify;

            this.notifyText = info.notifyText ? info.notifyText : false;
            this.notifyEmail = info.notifyEmail ? info.notifyEmail : false;
        }
    }

    setGamesProviders(providers) {
        this.providers = providers;
        this.selectedProvider = this.providers.find(provider => provider.default);
        this.pageForm.controls.method.setValue(this.selectedProvider);
    }

    onInputAmount() {
        const avaliable = typeof this.pageForm.controls.method.value.balance === 'number' ? this.pageForm.controls.method.value.balance : Number.parseFloat(this.pageForm.controls.method.value.balance);
        const entered = Number.parseFloat(this.pageForm.controls.amount.value);

        if (avaliable < entered) {
            this.pageForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    setTransactionsList(history: SenetproTransactionsModel[], limit: number, total: number): void {
        this.showMoreTransactionsBtn = total > limit;
        this.transactionsList = history;
    }

    setImagesLibraryList(history, limit: number, total: number): void {
        this.showMoreLibraryImagesBtn = total > limit;
        const list = [...history];
        if (list.length > 6) {
            list.length = 6;
        }
        this.imagesList = list;
    }

    setNftLibraryList(history, limit: number, total: number): void {
        this.showMoreLibraryNFTBtn = total > limit;
        const list = [...history];
        if (list.length > 6) {
            list.length = 6;
        }
        this.nftData.library = list;
    }

    showTransactionsPopup(): void {
        document.body.classList.add('hide-menu');
        this.popupTransactions = {
            show: true
        }
    }

    closeTransactionsPopup(): void {
        document.body.classList.remove('hide-menu');
        this.popupTransactions = {
            show: false
        }
    }

    showImagesLibraryPopup(): void {
        document.body.classList.add('hide-menu');
        this.popupImages = {
            show: true,
            type: POPUP_MORE_IMAGES_TYPE.image
        }
    }

    showNFTLibraryPopup(): void {
        document.body.classList.add('hide-menu');
        this.popupImages = {
            show: true,
            type: POPUP_MORE_IMAGES_TYPE.nft
        }
    }

    closeImagesPopup(): void {
        document.body.classList.remove('hide-menu');
        this.popupImages = {
            show: false,
            type: null
        }
    }



    onSellCrypto() {
        this.walletService.repeatFunction = this.onSellCrypto.bind(this);
        this.shopService.showSellCryptoPopup().subscribe(data => {
            this.sellCrypto(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    sellCrypto(data): void {
        try {
            this.walletService.resetRepeatFunction();
            if (data.status) {
                const rate = data.price;
                const crypto = data.bitcoinAddress;
                const qrCode = data.bitcoinQrcodeLink;
                const btc = data.bitcoinValue;
                this.onPopupShopOpen(data.description, SHOP_POPUP_TYPE.sellCrypto, null, rate, crypto, btc, qrCode);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onPopupShopOpen(title, type, id = null, rate = null, crypto = null, btc = null, qrCode = null, address = null, privateKey = null, publicKey = null, wif = null) {
        document.body.classList.add('hide-menu');
        const availableBalance = rate && btc ? rate * btc : type === SHOP_POPUP_TYPE.sendCrypto ? btc :this.entriesVal;
        this.popupShop = {
            show: true,
            title,
            type,
            id,
            rate,
            crypto,
            btc,
            qrCode,
            address,
            privateKey,
            publicKey,
            wif,
            availableBalance
        }
    }

    onPopupShopClose() {
        document.body.classList.remove('hide-menu');
        this.popupShop = {
            show: false,
            title: '',
            type: null
        }
    }

    onSendPopupForm($event) {
        if (this.popupShop.type === SHOP_POPUP_TYPE.revealKey) {

        } else if (this.popupShop.type === SHOP_POPUP_TYPE.revealKeyPassword) {
            const password = $event.password;
            this.accountService.onRevealPrivateKey(password).subscribe(data => {
                this.setRevealKeyPopup(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else if (this.popupShop.type === SHOP_POPUP_TYPE.sendCrypto) {
            const amount = $event.amount;
            const address = $event.address;
            const password = $event.password;
            this.accountService.onSendCrypto(amount, address, password).subscribe(data => {
                this.setActivateBuyRequestResult(data, true);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            const amount = $event.amount;
            const password = $event.password;
            this.shopService.onSellCryptoRequest(amount, password).subscribe(data => {
                this.setActivateBuyRequestResult(data, true);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    setActivateBuyRequestResult(data, updateBalance = false): void {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                if (updateBalance) {
                    this.getPageInfo();
                }
                this.onPopupShopClose();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showRevealKeyPopup() {
        const message = this.languageService.getString('messagesData', 'askToEnterPassword');
        this.onPopupShopOpen(message, SHOP_POPUP_TYPE.revealKeyPassword);
    }

    setRevealKeyPopup(data): void {
        this.initShowShopPopup(data, () => {
            const address = data.address;
            const privateKey = data.privateKey;
            const publicKey = data.publicKey;
            const wif = data.wif;
            this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.revealKey, null, null, null, null, null, address, privateKey, publicKey, wif);
        });
    }

    onReceiveCrypto() {
        this.accountService.onReceiveCrypto().subscribe(data => {
            this.showReceivePopup(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showReceivePopup(data): void {
        this.initShowShopPopup(data, () => {
            const address = data.address;
            this.onPopupShopOpen(data.text, SHOP_POPUP_TYPE.receiveCrypto, null, null, null, null, null, address);
        });
    }

    onSendCrypto() {
        const btc = this.bitcoinAmount;
        const title = this.languageService.getString('sendCrypto');
        this.onPopupShopOpen(title, SHOP_POPUP_TYPE.sendCrypto, null, null, null, btc);
    }

    initShowShopPopup(data, successFunction): void {
        try {
            if (data.status) {
                successFunction();
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onCheckboxChange(event: any, input) {
        if (input === 'text') {
            this.notifyText = event.target.checked;
        } else {
            this.notifyEmail = event.target.checked;
        }
    }

    onSaveContcts() {
        const firstName = this.contactsForm.controls.firstName.value;
        const lastName = this.contactsForm.controls.lastName.value;
        const address = this.contactsForm.controls.address.value;

        this.accountService.onSaveContacts(firstName, lastName, address, this.notifyText, this.notifyEmail).subscribe(data => {
            this.showSaveContactsResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showSaveContactsResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    changeUserInfo(type) {
        if ((type === CHANGE_USER_INFO_TYPE.emailVerify) || (type === CHANGE_USER_INFO_TYPE.phoneVerify)) {
            if (type === CHANGE_USER_INFO_TYPE.emailVerify) {
                this.accountService.onRequestVerifyEmail().subscribe(data => {
                    try {
                        if (data.status) {
                            this.popupComponent.showPopupSuccess({text: data.message});
                        } else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            } else if (type === CHANGE_USER_INFO_TYPE.phoneVerify) {
                this.accountService.onRequestVerifyPhone().subscribe(data => {
                    try {
                        if (data.status) {
                            this.showPopupChangeInfo(type)
                        } else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            }
        } else {
            this.showPopupChangeInfo(type);
        }
    }

    showPopupChangeInfo(type) {
        document.body.classList.add('hide-menu');
        this.popupChangeUserInfo = {
            show: true,
            type,
            email: (this.contactsForm.controls.email.value ? this.contactsForm.controls.email.value : null),
            phone: (this.contactsForm.controls.phone.value ? this.contactsForm.controls.phone.value : null),
            phoneLastFour: (this.contactsForm.controls.phone.value ? this.contactsForm.controls.phone.value.substr(-4) : null),
        }
    }

    closeChangeUserInfoPopup() {
        document.body.classList.remove('hide-menu');
        this.popupChangeUserInfo = {
            show: false,
            type: null
        }
    }

    onVerified(type) {
        if (type === 'phone') {
            this.isPhoneVerified = true;
        } else if (type === 'email') {
            this.isEmailVerified = true;
        }
    }

    setChangedUserInfo($event) {
        const type = $event.type;
        const value = $event.value;
        if (type === CHANGE_USER_INFO_TYPE.email) {
            this.contactsForm.controls.email.setValue(value);
        } else if (type === CHANGE_USER_INFO_TYPE.phone) {
            this.contactsForm.controls.phone.setValue(value);
        }
    }

    showAdditionalContent() {
        return this.contentDisplayingService.showAdditionContent();
    }


    onShowLibraryBigImagePopup(slide) {
        // document.body.classList.add('hide-menu');
        // document.querySelector('.login__forms').classList.add('blur');
        this.showLibraryBigImagePopup = true;
        this.libraryBigImagePopupData = {
            data: slide
        }
    }

    onPopupLibraryBigImageClose() {
        this.showLibraryBigImagePopup = false;
        // document.querySelector('.login__forms').classList.remove('blur');
    }

    onLibraryImageCheckboxChange(e, item) {
        const oldStatus = e.target.checked;
        const status = oldStatus ? 0 : 1;
        const uuid = item.uuid;
        this.accountService.onLibraryImageStatusChange(uuid, status).subscribe(data => {
            try {
                if (data.status) {
                    const changedImage = this.imagesList.find(el => el.uuid === uuid);
                    if (changedImage) {
                        changedImage.privacy_status = status;
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                    e.target.checked = !oldStatus;
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
                e.target.checked = !oldStatus;
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
            e.target.checked = !oldStatus;
        }, () => {
            this.loaderService.hide();
        });
    }

    onLibraryImageCheckboxPopupChange(e) {
        this.onLibraryImageCheckboxChange(e.event, e.item);
    }
}

