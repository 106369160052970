<button class="button"
        [class.is-loading]="showLoader"
        [ngStyle]="styleWidth ? {
            minWidth: styleWidth+'rem',
            width: styleWidth+'rem'
        } : {}"
        (click)="handleClick()"
        [disabled]="disabledInner || showLoader">
        <ng-content></ng-content>
        <div class="loader-btn-wrapper">
            <div *ngIf="showLoader" class="loader-btn"></div>
        </div>
</button>

