import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG, SENETPRO_CONFIG} from '../../../config/api';
import {BehaviorSubject, Subject} from 'rxjs';
import {LoaderService} from "../../../service/loader.service";
import {SessionStorageService} from "../../../service/session/sessionStorage.service";
import {PointService} from "../../../service/point.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ReferralBonusService} from "../../../service/referralBonus.service";
import {environment} from "../../../../environments/environment";
import {PosSettingsService} from "../../../service/pos-settings.service";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private config = AUTH_CONFIG;
    private configSenetPro = SENETPRO_CONFIG;

    private storage = sessionStorage;
    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();
    private authStamp;
    userLoggedStatusChanged = new BehaviorSubject(this.isUserLogged());

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private router: Router,
                private loaderService: LoaderService,
                private referralBonusService: ReferralBonusService,
                private pointService: PointService,
                private popupComponent: PopupComponent,
                private posSettingsService: PosSettingsService,
                private sessionStorageService: SessionStorageService
                ) {
    }

    isUserLogged() {
        return !!this.storage.getItem('currentUser');
    }

    // checkPhoneAndSendPin(phone: string, country: string){
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.checkPhoneAndSendPin, {phone:phone, country: country});
    // }

    checkPhoneAndSendPin(phone: string, email: string, authHash: string){
        this.loaderService.show();
        const body: any = {};
        if (phone) {
            body.phone = phone;
        }
        if (email) {
            body.email = email;
        }
        if (authHash) {
            body.authHash = authHash;
        }
        // if (environment.frontEndTest) {
        //     body.country = 'us';
        // }
        return this.http.post<any>(this.config.checkPhoneAndSendPin, body);
    }

    // register(phone: string, pin: string, country: string){
    //     this.loaderService.show();
    //     return this.http.post<any>(this.config.register, {phone:phone, pin:pin, country: country})   .pipe(map((res: any) => {
    //         if (res.status) {
    //             this.setPointAccounts(res.accounts);
    //         }
    //
    //         return res;
    //     }));
    // }

    register(firstName: string, lastName: string, email: string, promoCode: string, password: string, phone: string, pin: string){
        this.loaderService.show();
        return this.http.post<any>(this.config.register, {first_name: firstName, last_name: lastName, email: email, promo_code: promoCode, password: password, phone:phone, pin:pin})
          .pipe(map((res: any) => {
            if (res.status) {
                this.setPointAccounts(res.accounts);
            }

            return res;
        }));
    }

    addPassword(phone:string,firstName: string, lastName: string, email: string, password: string, pin: string){
        this.loaderService.show();
        return this.http.post<any>(this.config.addPassword, {phone:phone,firstName, lastName, email: email, password: password, pin:pin})
          .pipe(map((res: any) => {
              if (res.status) {
                  this.setUserLogged(res.result);
                  this.setPointAccounts(res.accounts);
                  localStorage.setItem('authHash', res.authHash);
              }

              return res;
          }));
    }

    // login(phone: string, pin: string) {
    //     this.loaderService.show();
    //
    //     this.storage = sessionStorage;
    //     return this.http.post<any>(this.config.login, {phone, pin})
    //       .pipe(map((res: any) => {
    //           //console.log(res)
    //           if (res.status) {
    //               this.setUserLogged(res.result);
    //               this.setPointAccounts(res.accounts);
    //           }
    //
    //           return res;
    //       }));
    // }

    login(email: string, phone: string, pin: string, password: string, code: string, authHash: string) {
        this.loaderService.show();
        this.storage = sessionStorage;

        let body: any = {
            code: code
        };
        if (email) {
            body.email = email;
        }
        if (phone) {
            body.phone = phone;
        }
        if (password) {
            body.password = password;
        }
        if (pin) {
            body.pin = pin;
        }
        if (authHash) {
            body.authHash = authHash;
        }

        // if (environment.frontEndTest) {
        //     body = {
        //         phone: phone,
        //         pin: pin,
        //         country: 'us'
        //     }
        // }

        return this.http.post<any>(this.config.login, body)
          .pipe(map((res: any) => {
              //console.log(res)
              if (res.status) {
                  this.setUserLogged(res.result);
                  this.setPointAccounts(res.accounts);
                  localStorage.setItem('authHash', res.authHash);
              }

              return res;
          }));
    }

    forgotPassword(email: string, phone: string){
        this.loaderService.show();
        const body: any = {};
        if (email) {
            body.email = email;
        }
        if (phone) {
            body.phone = phone;
        }
        return this.http.post<any>(this.config.forgotPassword, body);
    }

    restorePassword(code: string, email: string, password: string){
        this.loaderService.show();
        return this.http.post<any>(this.config.restorePassword, {code, email, password});
    }

    playNow(token) {
        this.loaderService.show();
        return this.http.post<any>(this.configSenetPro.playNow, {token})
          .pipe(map((res: any) => {
            //console.log(res)
            if (res.status) {
                this.setUserLogged(res.result);
                this.setPointAccounts(res.accounts);
            }

            return res;
        }));
    }

    setPointAccounts(accounts) {
        this.pointService.savePointAccounts(accounts);
    }

    logoutRequest(){
        return this.http.post<any>(this.config.logout, {});
    }

    setUserLogged(info) {
        this.setData(info);
        this.userLoggedIn.emit(true);
        this.referralBonusService.setReferralBonusStatus(0);
    }

    setData(result: string){
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
    }

    async logout() {
        if (this.storage.getItem('currentUser')) {
            this.logoutRequest().toPromise();
        }
        this.storage.removeItem('currentUser');
        this.pointService.resetPointsInfo();
        this.userLoggedIn.emit(false);
        this.sessionStorageService.cleanStorage();
        this.referralBonusService.setReferralBonusStatus(0);

        this.posSettingsService.removeStorePageVisibility();
        this.posSettingsService.removePaymentsAvailable();

        document.querySelectorAll('.investments-popup').forEach(popup => {
            popup.classList.remove('is-active');
        });
        this.popupComponent.closePopup();
    }


    refreshTokenNow(){
        if (this.storage.getItem('currentUser')) {
            this.http.post<any>(this.config.refresh, {}).subscribe(res => {
                if (res.status) {
                    this.setData(res.result);
                }else{
                    this.router.navigate(['/']);
                }

                return res;
            });
        }

        return false;
    }

    refreshToken() {
        let $this = this;
        setTimeout(function(){
            $this.refreshToken();
        },60*5*1000);

        this.refreshTokenNow();
    }

    clearStorage(){
        this.storage.removeItem('currentUser');
        this.referralBonusService.setReferralBonusStatus(0);
    }

    getCurrentUser(){
        if (sessionStorage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

                return currentUser;
            }catch (e) {

            }
        }

        return false;
    }

    getToken(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.token;
            }catch (e) {

            }

        }

        return '';
    }
}
