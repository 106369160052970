import { USER_BALANCE_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var UserBalanceService = /** @class */ (function () {
    function UserBalanceService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = USER_BALANCE_CONFIG;
    }
    UserBalanceService.prototype.getBalance = function () {
        this.loaderService.show();
        return this.http.post(this.config.getBalance, {});
    };
    UserBalanceService.prototype.increaseBalance = function (amount, gameProviderId) {
        this.loaderService.show();
        return this.http.post(this.config.increaseBalance, { amount: amount, gameProviderId: gameProviderId });
    };
    UserBalanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserBalanceService_Factory() { return new UserBalanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: UserBalanceService, providedIn: "root" });
    return UserBalanceService;
}());
export { UserBalanceService };
